import React, { FC, useState } from 'react';
import { cn } from '@/lib/utils';
import { Button, ButtonVariant } from '@/components/ui/button';
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover';
import { FaCheck } from 'react-icons/fa6';
import { RxCaretSort } from 'react-icons/rx';

const periods = [
  {
    value: 'weekly',
    label: 'Weekly'
  },
  {
    value: 'monthly',
    label: 'Monthly'
  },
  {
    value: 'quarterly',
    label: 'Quarterly'
  }
];

const SchedulingDropdown: FC = () => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');

  const onSelect = (currentValue: string) => {
    setValue(currentValue === value ? '' : currentValue);
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant={ButtonVariant.OUTLINE}
          role="combobox"
          aria-expanded={open}
          className="w-3/5 justify-between">
          {value
            ? periods.find((period) => period.value === value)?.label
            : 'Scheduling'}
          <RxCaretSort className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[240px] p-0">
        <Command>
          <CommandList>
            <CommandGroup>
              {periods.map((period) => (
                <CommandItem
                  key={period.value}
                  value={period.value}
                  onSelect={onSelect}>
                  {period.label}
                  <FaCheck
                    className={cn(
                      'ml-auto h-4 w-4',
                      value === period.value ? 'opacity-100' : 'opacity-0'
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
export default SchedulingDropdown;
