import React, { FC, useMemo } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '@/components/ui/accordion';
import WordCloudChart from '@ui/charts/WordCloudChart';
import ScoreChart from '@ui/charts/ScoreChart';
import { getRoundNumber } from '@utils/numbers';
import InterestChart from '@ui/charts/InterestChart';
import PieChart from '@ui/charts/PieChart';
import RaceBarChart from '@ui/charts/RaceBarChart';
import {
  getChartData,
  getPieChartData,
  getTopicChartData
} from '@utils/csatHelpers';
import BarChart from '@ui/charts/BarChart';
import { groupRatingData, groupTrendsData } from '@utils/chartHelper';
import {
  IScore,
  IScoreResponse,
  ISentimentOverTime
} from '@all-types/csat-types';
import AreaChart from '@ui/charts/AreaChart';

interface IAnalysisTabProps {
  avgData?: IScoreResponse | null;
  isAvgRatingLoading: boolean;
  avgScoreByCategory: any;
  isAvgScoreByCategoryLoading: boolean;
  avgScoreByAgent: any;
  isAvgScoreByAgentLoading: boolean;
  avgScoreByTeam: any;
  isAvgScoreByTeamLoading: boolean;
  filledRatio?: number | null;
  isFilledRatioLoading: boolean;
  categories?: IScoreResponse | null;
  sentiment: any;
  sentimentByRating: any;
  sentimentByTopic: any;
  tags: any;
  isTagsLoading: boolean;
  isRatingEnabled: boolean;
  isAgentEnabled: boolean;
  isTeamEnabled: boolean;
  isSentimentLoading: boolean;
  isSentimentByRatingLoading: boolean;
  isSentimentByTopicLoading: boolean;
  isTopicsLoading: boolean;
  sentimentOverTime?: ISentimentOverTime | null;
  isSentimentOverTimeLoading: boolean;
}

const getAvgScoreByAgentData = (data: IScore) => {
  const chartData = data && getChartData(data);
  return chartData?.sort((a, b) => b.data[0] - a.data[0]);
};

const AnalysisTab: FC<IAnalysisTabProps> = ({
  avgData,
  isAvgRatingLoading,
  avgScoreByCategory,
  isAvgScoreByCategoryLoading,
  avgScoreByAgent,
  isAvgScoreByAgentLoading,
  avgScoreByTeam,
  isAvgScoreByTeamLoading,
  filledRatio,
  isFilledRatioLoading,
  categories,
  isTopicsLoading,
  sentiment,
  isSentimentLoading,
  sentimentByRating,
  isSentimentByRatingLoading,
  sentimentByTopic,
  isSentimentByTopicLoading,
  sentimentOverTime,
  isSentimentOverTimeLoading,
  tags,
  isTagsLoading,
  isRatingEnabled,
  isAgentEnabled,
  isTeamEnabled
}) => {
  const satisfactionRate = useMemo(() => {
    if (avgData?.distribution) {
      const values = Object.values(avgData.distribution);
      // get an array of the last 40% of all values
      const lastValues = values.slice(-Math.floor(values.length * 0.4));
      const percent =
        lastValues.reduce((acc, item) => {
          return acc + item.percent;
        }, 0) * 100;
      return [getRoundNumber(percent)];
    }
  }, [avgData]);

  const surveyFilledRatioData = useMemo(() => {
    if (typeof filledRatio === 'number') {
      const filled = filledRatio * 100;
      const notFilledRatio = 100 - filled;
      return [
        {
          name: 'Filled',
          y: filled
        },
        {
          name: 'Not Filled',
          y: notFilledRatio
        }
      ];
    }
  }, [filledRatio]);

  const avgScoreByCategoryData = useMemo(() => {
    return getChartData(avgScoreByCategory?.category);
  }, [avgScoreByCategory]);

  const avgScoreByTeamData = useMemo(() => {
    return getChartData(avgScoreByTeam?.team);
  }, [avgScoreByTeam]);

  const avgScoreByAgentData = useMemo(() => {
    return getAvgScoreByAgentData(avgScoreByAgent?.agent);
  }, [avgScoreByAgent]);

  const topicsData = useMemo(() => {
    return getTopicChartData(categories);
  }, [categories]);

  const sentimentData = useMemo(() => {
    return getPieChartData(sentiment?.distribution);
  }, [sentiment]);

  const sentimentByRatingData = useMemo(() => {
    return {
      data: groupRatingData(sentimentByRating),
      keys: Object.keys(sentimentByRating || {})
    };
  }, [sentimentByRating]);

  const sentimentByTopicData = useMemo(() => {
    return {
      data: groupRatingData(sentimentByTopic),
      keys: Object.keys(sentimentByTopic || {}).filter((key) => key !== 'total')
    };
  }, [sentimentByTopic]);

  const sentimentOverTimeData = useMemo(() => {
    return groupTrendsData(sentimentOverTime);
  }, [sentimentOverTime]);

  const yAgentMax = useMemo(() => {
    let max = 0;
    avgScoreByAgentData?.forEach((item: any) => {
      item.data.forEach((value: number) => {
        if (value > max) {
          max = value;
        }
      });
    });
    return max;
  }, [avgScoreByAgentData]);

  const isGeneralSectionHidden = useMemo(() => {
    return !(
      sentimentOverTimeData.categories.length ||
      tags?.tag ||
      avgData ||
      satisfactionRate?.length ||
      surveyFilledRatioData?.length
    );
  }, [
    sentimentOverTimeData,
    tags,
    avgData,
    satisfactionRate,
    surveyFilledRatioData
  ]);

  const isCategoriesSectionHidden = useMemo(() => {
    return !(avgScoreByCategoryData?.length || topicsData?.length);
  }, [avgScoreByCategoryData, topicsData]);

  const isSentimentSectionHidden = useMemo(() => {
    return !(
      sentimentByRatingData?.data.length ||
      sentimentData?.length ||
      sentimentByTopicData?.data.length
    );
  }, [sentimentByRatingData, sentimentData, sentimentByTopicData]);

  const isCSATSectionHidden = useMemo(() => {
    return !(avgScoreByAgentData?.length || avgScoreByTeamData?.length);
  }, [avgScoreByAgentData, avgScoreByTeamData]);

  return (
    <Accordion
      type="multiple"
      defaultValue={['general', 'categories', 'sentiment', 'csat']}
      className="w-full">
      <AccordionItem
        value="general"
        className="border-b-0"
        hidden={isGeneralSectionHidden}>
        <AccordionTrigger className="!justify-start gap-1 font-semibold text-gray-500 max-w-fit">
          General
        </AccordionTrigger>
        <AccordionContent className="flex flex-col gap-5 pb-0">
          {!!sentimentOverTimeData.categories.length && (
            <AreaChart
              wrapperClassName="w-full md:h-[440px] h-[410px]"
              title="Sentiment over Time"
              data={sentimentOverTimeData.data}
              categories={sentimentOverTimeData.categories}
              isLoading={isSentimentOverTimeLoading}
            />
          )}
          <div className="flex gap-5 w-full">
            <WordCloudChart
              values={tags?.tag}
              wrapperClassName="w-full h-80"
              isLoading={isTagsLoading}
            />
          </div>
          <div className="flex gap-5 lg:flex-row flex-col lg:w-full">
            {isRatingEnabled && (
              <ScoreChart
                wrapperClassName="w-full lg:w-1/3 overflow-hidden lg:h-96 h-60 flex justify-center text-main"
                title="Average Score"
                value={avgData?.total?.avg}
                isLoading={isAvgRatingLoading}
              />
            )}
            <div className="lg:w-2/3 w-full gap-5 flex md:flex-row flex-col">
              {isRatingEnabled && (
                <InterestChart
                  wrapperClassName="w-full md:w-3/5 lg:w-1/2 overflow-hidden h-96"
                  title="Satisfaction Rate"
                  data={satisfactionRate}
                  isLoading={isAvgRatingLoading}
                  height={340}
                  width={240}
                />
              )}
              <PieChart
                wrapperClassName="w-full md:w-2/5 lg:w-1/2 h-96 "
                title="Survey filled Ratio"
                data={surveyFilledRatioData}
                isLoading={isFilledRatioLoading}
              />
            </div>
          </div>
        </AccordionContent>
      </AccordionItem>
      <AccordionItem
        value="categories"
        className="border-b-0"
        hidden={isCategoriesSectionHidden}>
        <AccordionTrigger className="!justify-start gap-1 font-semibold text-gray-500 max-w-fit">
          Categories
        </AccordionTrigger>
        <AccordionContent className="flex lg:flex-row flex-col gap-5 pb-0">
          {isRatingEnabled && (
            <RaceBarChart
              wrapperClassName="w-full lg:w-1/2 md:h-[440px] h-[410px]"
              data={avgScoreByCategoryData}
              title="Average Score by Category"
              yAxisLabel="Score"
              isLoading={isAvgScoreByCategoryLoading}
            />
          )}
          <RaceBarChart
            wrapperClassName="w-full lg:w-2/3 md:h-[440px] h-[410px]"
            data={topicsData}
            title="Categories"
            yAxisLabel="Category"
            isLoading={isTopicsLoading}
            showPercentLabel={true}
          />
        </AccordionContent>
      </AccordionItem>
      <AccordionItem
        value="sentiment"
        className="border-b-0"
        hidden={isSentimentSectionHidden}>
        <AccordionTrigger className="!justify-start gap-1 font-semibold text-gray-500 max-w-fit">
          Sentiment
        </AccordionTrigger>
        <AccordionContent className="flex flex-col gap-5 pb-0">
          <div className="flex lg:flex-row flex-col gap-5">
            {isRatingEnabled && (
              <BarChart
                wrapperClassName="w-full lg:w-1/2 md:h-[440px] h-[410px]"
                data={sentimentByRatingData.data}
                title="Sentiment by Rating"
                yAxisLabel="Score"
                categories={sentimentByRatingData.keys}
                stacking="normal"
                isLoading={isSentimentByRatingLoading}
              />
            )}
            <PieChart
              wrapperClassName="w-full lg:w-1/2 md:h-[440px] h-[410px]"
              title="Overall Sentiment"
              data={sentimentData}
              isLoading={isSentimentLoading}
            />
          </div>
          <RaceBarChart
            wrapperClassName="w-full md:h-[440px] h-[410px]"
            data={sentimentByTopicData.data}
            title="Sentiment by Category"
            yAxisLabel="Percentage"
            stacking="percent"
            categories={sentimentByTopicData.keys}
            isLoading={isSentimentByTopicLoading}
          />
        </AccordionContent>
      </AccordionItem>
      <AccordionItem
        value="csat"
        className="border-b-0"
        hidden={isCSATSectionHidden}>
        <AccordionTrigger className="!justify-start gap-1 font-semibold text-gray-500 max-w-fit">
          CSAT
        </AccordionTrigger>
        <AccordionContent className="flex flex-col gap-5 pb-0">
          {isAgentEnabled && (
            <BarChart
              wrapperClassName="w-full md:h-[440px] h-[410px]"
              data={avgScoreByAgentData}
              title="Average Score by Agent"
              yAxisLabel="Score"
              xAxisLabel="Agent"
              isLoading={isAvgScoreByAgentLoading}
              yAxisOptions={{ max: yAgentMax, tickInterval: 1 }}
              legendOptions={{ maxHeight: 140 }}
            />
          )}
          {isTeamEnabled && (
            <RaceBarChart
              wrapperClassName="w-full md:h-[440px] h-[410px]"
              data={avgScoreByTeamData}
              title="Average Score by Team"
              yAxisLabel="Score"
              isLoading={isAvgScoreByTeamLoading}
            />
          )}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default AnalysisTab;
