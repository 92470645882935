import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import { Input } from '@/components/ui/input';
import { Button, ButtonVariant } from '@/components/ui/button';
import { BsSendFill } from 'react-icons/bs';

interface IChatInputProps {
  setHasSentMessage: (value: boolean) => void;
  sendMessage: (question: string) => Promise<void>;
  disabled?: boolean;
}

const ChatInput = ({
  setHasSentMessage,
  sendMessage,
  disabled = false
}: IChatInputProps) => {
  const [question, setQuestion] = useState<string>('');

  const onSendMessage = async () => {
    const trimmedQuestion = question.trim();
    if (trimmedQuestion) {
      setHasSentMessage(true);
      setQuestion('');
      await sendMessage(trimmedQuestion);
    }
  };

  const onMessageChange = (value: string) => {
    setQuestion(value);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onMessageChange(e.target.value);
  };

  const handleKeyUp = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      await onSendMessage();
    }
  };

  return (
    <div className="flex items-center flex-row gap-4">
      <Input
        disabled={disabled}
        value={question}
        placeholder="Send a message"
        onChange={handleInputChange}
        onKeyUp={handleKeyUp}
      />
      <div className="pt-4 flex justify-end pb-4">
        <Button
          disabled={disabled}
          variant={ButtonVariant.DEFAULT}
          onClick={onSendMessage}>
          <BsSendFill />
        </Button>
      </div>
    </div>
  );
};

export default ChatInput;
