import React, { useMemo, useState } from 'react';
import { MdDone } from 'react-icons/md';
import { TfiReload } from 'react-icons/tfi';
import { AiOutlineWarning } from 'react-icons/ai';
import { cn } from '@/lib/utils';
import { Button, ButtonVariant } from '@/components/ui/button';
import Spinner from '@ui/layout/Spinner';
import InputDialog from '@ui/dialogs/InputDialog';
import { IFeedbackStatus, IIntegrationStatus } from '@all-types/csat-types';
import DatePicker from '@ui/forms/DatePicker';
import StatusInfoButton from '@ui/status/StatusInfoButton';

interface IServiceConnectBoxProps {
  onConnect: () => void;
  createSpaceAndConnect: (name: string) => void;
  icon: JSX.Element;
  name: string;
  onStartProcessing: (date?: Date) => Promise<void>;
  isLoading?: boolean;
  isRunning: boolean;
  isSuccessful: boolean;
  isFailed: boolean;
  isConnected?: boolean;
  isSelected?: boolean;
  isUsed?: boolean;
  allowProcessing?: boolean;
  integrationStatus?: IIntegrationStatus['state'];
  feedbackStatus?: IFeedbackStatus;
}

const ServiceConnectBox = ({
  icon = <AiOutlineWarning size={48} />,
  name = 'Default',
  isSelected = false,
  isConnected,
  isLoading = false,
  isUsed = false,
  isRunning,
  isFailed,
  isSuccessful,
  onConnect,
  createSpaceAndConnect,
  onStartProcessing,
  integrationStatus,
  feedbackStatus,
  allowProcessing = false
}: IServiceConnectBoxProps) => {
  const [integrationStartDate, setIntegrationStartDate] = useState<
    Date | undefined
  >();

  const connectButton = useMemo(() => {
    if (isConnected) {
      return (
        <Button
          variant={ButtonVariant.OUTLINE}
          disabled={isLoading}
          onClick={onConnect}>
          <TfiReload /> Reconnect
        </Button>
      );
    }
    if (isUsed) {
      return (
        <InputDialog
          defaultValue="Intercom"
          title="Create new space"
          inputPlaceholder="Enter space name"
          buttonTitle="Create"
          onApply={createSpaceAndConnect}
          maxCharacters={30}>
          <Button disabled={isLoading}>Connect</Button>
        </InputDialog>
      );
    }
    return (
      <Button onClick={onConnect} disabled={isLoading}>
        Connect
      </Button>
    );
  }, [isConnected, isLoading, onConnect]);

  return (
    <div
      className={cn(
        'justify-center bg-white rounded-[10px] mt-5 max-w-[900px] text-left',
        isSelected ? 'block' : 'hidden'
      )}>
      <div className="flex justify-between p-4 items-center">
        <div className="flex items-center gap-4">
          <div>{isSelected && icon}</div>
          {name}
        </div>
        {isConnected && (
          <div className="flex flex-row justify-between gap-1">
            <MdDone size={24} />
            <div className="font-bold">Connected</div>
          </div>
        )}
        <div>{connectButton}</div>
      </div>
      <hr className="w-full" />
      <div className="p-6">
        {isConnected ? (
          <div className="flex flex-col justify-between gap-4">
            <div className="flex justify-end">
              <StatusInfoButton
                isRunning={isRunning}
                isFailed={isFailed}
                isSuccessful={isSuccessful}
                integrationStatus={integrationStatus}
                feedbackStatus={feedbackStatus}
              />
            </div>
            <div className="flex flex-col justify-between gap-4">
              <span className="block self-center">
                Select the starting date from which you want to analyze your
                data and start processing
              </span>
              <div className="flex flex-col gap-6 items-center">
                <div className="flex gap-2 items-center">
                  <span className="text-gray-400">Start date:</span>
                  <DatePicker
                    disabled={!allowProcessing}
                    onChange={setIntegrationStartDate}
                    defaultValue={integrationStartDate}
                  />
                </div>
                <Button
                  className="self-center"
                  onClick={() => onStartProcessing(integrationStartDate)}
                  disabled={!allowProcessing}>
                  Start processing
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <>
            {isLoading ? (
              <div className="flex flex-col h-48 justify-center items-center">
                <Spinner classNameWrapper="h-12" />
                <span className="font-bold text-xl pt-4">
                  Gathering and processing data...
                </span>
              </div>
            ) : (
              <div className="flex flex-col px-4 text-wrap">
                <span className="font-bold text-start">
                  Sync customer feedback with {name}
                </span>
                <span className="text-start max-w-[800px] py-4">
                  Unwink helps you replace the manual tagging process and gives
                  you deeper customer insights with pain points discovery, MRR
                  and sentiment analysis. Get all your customer feedback
                  automatically tagged by topic and sentiment with the most
                  accurate feedback analytics software.
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ServiceConnectBox;
