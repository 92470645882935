import React, { FC } from 'react';
import Spinner from '@ui/layout/Spinner';
import { Button, ButtonVariant } from '@/components/ui/button';
import { MessageType } from '@hooks/useInsights';
import AskAiChat from '@ui/chat/AskAiChat';

interface IInsightsProps {
  messages: MessageType[];
  insights: any;
  isInsightsLoading: boolean;
  refetchInsights: () => void;
  sendMessage: (question: string) => Promise<void>;
  hasSentMessage: boolean;
  setHasSentMessage: (value: boolean) => void;
  clearChat: () => void;
  isInsightsDisabled?: boolean;
  isProcessing?: boolean;
}

const InsightsTab: FC<IInsightsProps> = ({
  messages,
  insights,
  isInsightsLoading,
  refetchInsights,
  sendMessage,
  hasSentMessage,
  setHasSentMessage,
  clearChat,
  isInsightsDisabled = false,
  isProcessing = false
}) => {
  const handleAskButtonClick = async () => {
    await refetchInsights();
  };

  const getInsightsSection = (sectionData: string | string[]) => {
    return Array.isArray(sectionData) ? (
      <ul className="text-left list-disc pl-6">
        {sectionData.map((item: any, index: number) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    ) : (
      <div className="text-left">{sectionData}</div>
    );
  };

  const getPromptSection = () => {
    return (
      <AskAiChat
        clearChat={clearChat}
        messages={messages}
        hasSentMessage={hasSentMessage}
        setHasSentMessage={setHasSentMessage}
        sendMessage={sendMessage}
        isInsightsLoading={isInsightsLoading}
        isProcessing={isProcessing}
      />
    );
  };

  if (typeof insights === 'string') {
    return (
      <div className="bg-white w-full flex flex-row gap-10 px-8 py-4 border border-gray-100">
        <div className="w-3/5 border-r border-r-gray-100 pr-8">
          <div className="flex flex-col items-start gap-4">
            <div className="font-bold">Summary:</div>
            <div className="text-left">{`${insights}`}</div>
          </div>
        </div>
        {getPromptSection()}
      </div>
    );
  }

  return (
    <div className="bg-white w-full flex gap-5 justify-between px-8 py-4 border border-gray-100">
      <div className="flex flex-row w-full h-[calc(100vh-206px)]">
        <div className="flex flex-col w-1/2 gap-8 border-r border-r-gray-100 pr-8 overflow-y-auto h-full">
          {insights || (
            <div className="bg-white w-full h-full flex flex-col justify-center items-center">
              <p className="text-gray-700 text-2xl w-full pb-8">
                Use our
                <span className="text-bold text-darkCyan">
                  &nbsp;Get Insights&nbsp;
                </span>
                functionality to obtain executive summaries and valuable
                insights.
              </p>
              <div className="flex h-24 flex-col">
                <Button
                  disabled={isInsightsLoading || isInsightsDisabled}
                  variant={ButtonVariant.DEFAULT}
                  onClick={() => handleAskButtonClick()}>
                  <span>Get Insights</span>
                </Button>
                {isInsightsLoading && (
                  <Spinner
                    classNameSpinner="w-8 h-8"
                    classNameWrapper="!h-10 mt-4"
                  />
                )}
              </div>
            </div>
          )}
        </div>
        {getPromptSection()}
      </div>
    </div>
  );
};

export default InsightsTab;
