import { useContext, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
// import { getRoundNumber } from '@utils/numbers';
import { IFilters } from '@all-types/csat-types';
import CsatAPI from '@api/csatAPI';
import { SpaceContext, SpaceContextType } from '@context/contexts';

interface IFeedbackAnalysisProps {
  id: string;
  triggerRefetch: boolean;
  appliedFilters?: Partial<IFilters>;
}

const useFeedbackAnalysis = ({
  id,
  appliedFilters,
  triggerRefetch
}: IFeedbackAnalysisProps) => {
  const csatAPI = CsatAPI.getInstance();
  const { dataSchema, currentSpace } =
    useContext<SpaceContextType>(SpaceContext);
  const currentSpaceId = currentSpace?.id;
  const dateSchema = dataSchema.date;
  const isRatingEnabled = !!dataSchema.rating;
  const isAgentEnabled = isRatingEnabled && !!dataSchema.agent;
  const isTeamEnabled = isRatingEnabled && !!dataSchema.team;

  const {
    data: tags,
    isLoading: isTagsLoading,
    refetch: refetchTags
  } = useQuery({
    queryKey: ['tags', currentSpaceId as string, appliedFilters],
    queryFn: () => csatAPI.getTags(currentSpaceId as string, appliedFilters)
  });
  const {
    data: avgData,
    isLoading: isAvgRatingLoading,
    refetch: refetchAvgData
  } = useQuery({
    queryKey: ['avgData', currentSpaceId as string, appliedFilters],
    queryFn: () =>
      csatAPI.getAvgScore(currentSpaceId as string, 'rating', appliedFilters),
    enabled: isRatingEnabled
  });
  const {
    data: filledRatio,
    isLoading: isFilledRatioLoading,
    refetch: refetchFilledRatio
  } = useQuery({
    queryKey: ['filledRatio', currentSpaceId as string, appliedFilters],
    queryFn: async () => {
      // const ratioResponse = await csatAPI.getFilledRatio(
      //   currentSpaceId as string,
      //   'text',
      //   appliedFilters
      // );
      // temporary simulate request
      function wait(milliseconds: number) {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
      }
      await wait(5000);
      // return ratioResponse ? getRoundNumber(ratioResponse) : null;
      return 0.23;
    },
    enabled: true
  });
  const {
    data: avgScoreByCategory,
    isLoading: isAvgScoreByCategoryLoading,
    refetch: refetchAvgScoreByCategory
  } = useQuery({
    queryKey: ['avgScoreByCategory', currentSpaceId as string, appliedFilters],
    queryFn: () =>
      csatAPI.getAvgScoreBy(
        currentSpaceId as string,
        'rating',
        'category',
        appliedFilters
      ),
    networkMode: 'offlineFirst',
    enabled: isRatingEnabled
  });
  const {
    data: avgScoreByAgent,
    isLoading: isAvgScoreByAgentLoading,
    refetch: refetchAvgScoreByAgent
  } = useQuery({
    queryKey: ['avgScoreByAgent', currentSpaceId as string, appliedFilters],
    queryFn: () =>
      csatAPI.getAvgScoreBy(
        currentSpaceId as string,
        'rating',
        'agent',
        appliedFilters
      ),
    enabled: isAgentEnabled
  });
  const {
    data: avgScoreByTeam,
    isLoading: isAvgScoreByTeamLoading,
    refetch: refetchAvgScoreByTeam
  } = useQuery({
    queryKey: ['avgScoreByTeam', currentSpaceId as string, appliedFilters],
    queryFn: () =>
      csatAPI.getAvgScoreBy(
        currentSpaceId as string,
        'rating',
        'team',
        appliedFilters
      ),
    enabled: isTeamEnabled
  });
  const {
    data: categories,
    isLoading: isTopicsLoading,
    refetch: refetchCategories
  } = useQuery({
    queryKey: ['categories', currentSpaceId as string, appliedFilters],
    queryFn: () =>
      csatAPI.getCategories(
        currentSpaceId as string,
        'category',
        appliedFilters
      )
  });
  const {
    data: sentiment,
    isLoading: isSentimentLoading,
    refetch: refetchSentiments
  } = useQuery({
    queryKey: ['sentiment', currentSpaceId as string, appliedFilters],
    queryFn: () =>
      csatAPI.getSentiment(
        currentSpaceId as string,
        'sentiment',
        appliedFilters
      )
  });
  const {
    data: sentimentByRating,
    isLoading: isSentimentByRatingLoading,
    refetch: refetchSentimentByRating
  } = useQuery({
    queryKey: ['sentimentByRating', currentSpaceId as string, appliedFilters],
    queryFn: () =>
      csatAPI.getSentimentBy(
        currentSpaceId as string,
        'rating',
        appliedFilters
      ),
    enabled: isRatingEnabled
  });
  const {
    data: sentimentByTopic,
    isLoading: isSentimentByTopicLoading,
    refetch: refetchSentimentByTopic
  } = useQuery({
    queryKey: ['sentimentByTopic', currentSpaceId as string, appliedFilters],
    queryFn: () =>
      csatAPI.getSentimentBy(
        currentSpaceId as string,
        'category',
        appliedFilters
      )
  });

  const {
    data: sentimentOverTime,
    isLoading: isSentimentOverTimeLoading,
    refetch: refetchSentimentOverTime
  } = useQuery({
    queryKey: ['sentimentOverTime', currentSpaceId as string, appliedFilters],
    queryFn: () =>
      csatAPI.getSentimentOverTimeTrends(
        currentSpaceId as string,
        dateSchema,
        appliedFilters
      ),
    enabled: !!dateSchema
  });

  const refetchAll = async () => {
    await refetchAvgData();
    await refetchFilledRatio();
    await refetchAvgScoreByCategory();
    await refetchAvgScoreByAgent();
    await refetchAvgScoreByTeam();
    await refetchCategories();
    await refetchSentiments();
    await refetchSentimentByRating();
    await refetchSentimentByTopic();
    await refetchTags();
    await refetchSentimentOverTime();
  };

  useEffect(() => {
    if (triggerRefetch) {
      refetchAll();
    }
  }, [triggerRefetch]);

  return {
    avgData,
    isAvgRatingLoading,
    avgScoreByCategory,
    isAvgScoreByCategoryLoading,
    avgScoreByAgent,
    isAvgScoreByAgentLoading,
    avgScoreByTeam,
    isAvgScoreByTeamLoading,
    filledRatio,
    isFilledRatioLoading,
    categories,
    isTopicsLoading,
    sentiment,
    isSentimentLoading,
    sentimentByRating,
    isSentimentByRatingLoading,
    sentimentByTopic,
    isSentimentByTopicLoading,
    tags,
    isTagsLoading,
    sentimentOverTime,
    isSentimentOverTimeLoading,
    isRatingEnabled,
    isAgentEnabled,
    isTeamEnabled
  };
};

export default useFeedbackAnalysis;
