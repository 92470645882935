import React, { FC } from 'react';
import { toast } from 'sonner';
import { BiSolidError } from 'react-icons/bi';
import {
  IoIosCheckmarkCircle,
  IoMdClose,
  IoIosInformationCircle
} from 'react-icons/io';

interface IToastErrorMessageProps {
  title: string;
  text?: string;
  icon: JSX.Element;
  toastId: string;
}

export const ToastErrorMessage: FC<IToastErrorMessageProps> = ({
  title = 'Something went wrong!',
  text,
  icon,
  toastId
}) => {
  return (
    <div className="msg-container flex w-full justify-between">
      <div className="flex">
        <div className="flex items-center justify-center pr-3">{icon}</div>
        <div>
          <p className="msg-title text-left text-sm font-medium text-primary-25">
            {title}
          </p>
          {text && (
            <p className="msg-description text-left text-sm font-normal text-primary-25">
              {text}
            </p>
          )}
        </div>
      </div>
      <div
        onClick={() => toast.dismiss(toastId)}
        className="focus:outline-none text-primary-25 flex justify-end items-center cursor-pointer">
        <IoMdClose size="24px" />
      </div>
    </div>
  );
};

export const toaster = (myProps: any, toastProps: any = {}): any => {
  const toastId = toastProps.id || Math.random().toString(36).substr(2, 9);

  return toast(<ToastErrorMessage {...myProps} toastId={toastId} />, {
    ...toastProps,
    id: toastId
  });
};

toaster.error = (myProps: any, toastProps: any = {}): any => {
  const toastId = toastProps.id || Math.random().toString(36).substr(2, 9);

  return toast.error(
    <ToastErrorMessage
      {...myProps}
      icon={<BiSolidError size="24px" />}
      toastId={toastId}
    />,
    { ...toastProps, id: toastId, style: { border: 'none', borderRadius: '0' } }
  );
};

toaster.info = (myProps: any, toastProps: any = {}): any => {
  const toastId = toastProps.id || Math.random().toString(36).substr(2, 9);

  return toast.info(
    <ToastErrorMessage
      {...myProps}
      icon={<IoIosInformationCircle size="24px" />}
      toastId={toastId}
    />,
    { ...toastProps, id: toastId, style: { border: 'none', borderRadius: '0' } }
  );
};

toaster.success = (myProps: any, toastProps: any = {}): any => {
  const toastId = toastProps.id || Math.random().toString(36).substr(2, 9);

  return toast.success(
    <ToastErrorMessage
      icon={<IoIosCheckmarkCircle size="24px" />}
      {...myProps}
      toastId={toastId}
    />,
    { ...toastProps, id: toastId, style: { border: 'none', borderRadius: '0' } }
  );
};
