import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import Markdown from 'react-markdown';
import startCase from 'lodash/startCase';
import { IFilters, IInsightPrompt } from '@all-types/csat-types';
import CsatAPI from '@api/csatAPI';
import { getValueByType } from '@utils/structureHelper';
import BlinkingDots from '@ui/blinkingDots/BlinkingDots';
import { SpaceContext, SpaceContextType } from '@context/contexts';

export type MessageType = {
  message: JSX.Element | string | string[];
  sentTime: string;
  sender: string;
};

interface IFeedbackAnalysisProps {
  id: string;
  appliedFilters?: Partial<IFilters>;
}

function list(arr: any) {
  if (typeof arr === 'string') {
    return arr;
  }

  if (typeof arr === 'number') {
    return arr;
  }

  if (Array.isArray(arr)) {
    return arr.map((item) => {
      return (
        <div className="flex items-start flex-col pl-5 text-left" key={item}>
          {list(item)}
        </div>
      );
    });
  }

  return Object.entries(arr).map(([key, value]) => {
    return (
      <div className="flex items-start flex-col pl-5" key={key}>
        <h4 className="font-bold inline-block mb-0 mt-0">{startCase(key)}:</h4>{' '}
        {list(value)}
      </div>
    );
  });
}

const useInsights = ({ id, appliedFilters }: IFeedbackAnalysisProps) => {
  const csatAPI = CsatAPI.getInstance();
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);

  const { currentSpace } = useContext<SpaceContextType>(SpaceContext);
  const currentSpaceId = currentSpace?.id;

  const {
    data: insightsResponse,
    isLoading: isInsightsLoading,
    refetch: refetchInsights
  } = useQuery({
    queryKey: ['insights', currentSpaceId, appliedFilters],
    queryFn: () => csatAPI.getInsights(currentSpaceId!, appliedFilters),
    enabled: false
  });

  const {
    data: insightsReportResponse,
    isLoading: isInsightsReportLoading,
    refetch: refetchReportInsights
  } = useQuery({
    queryKey: ['report', currentSpaceId, appliedFilters],
    queryFn: () => csatAPI.getReportInsights(currentSpaceId!, appliedFilters),
    enabled: !!currentSpaceId
  });

  const {
    data: insightsSummaryResponse,
    isLoading: isReportSummaryLoading,
    refetch: refetchReportSummary
  } = useQuery({
    queryKey: ['summary', currentSpaceId, appliedFilters],
    queryFn: () => csatAPI.getReportSummary(currentSpaceId!, appliedFilters),
    enabled: !!currentSpaceId
  });

  const askAI = useCallback(
    async (question: string) => {
      const controller = new AbortController();
      setAbortController(controller);
      setIsProcessing(true);
      setMessages((prev) => [
        ...prev,
        {
          message: <BlinkingDots />,
          sentTime: new Date().toISOString(),
          sender: 'AI'
        }
      ]);

      const response = await csatAPI.getInsightsByQuestion(
        currentSpaceId!,
        question,
        appliedFilters,
        { signal: controller.signal }
      );

      if (controller.signal.aborted) {
        return;
      }

      let answer: string | string[] = '';
      if (
        typeof response === 'object' &&
        Object.keys(response as IInsightPrompt).length === 0
      ) {
        answer = 'I am not sure what you are asking.';
      } else if (response) {
        answer = getValueByType(response);
      }
      if (answer === '') {
        answer = 'I am not sure what you are asking.';
      }
      const formattedAnswer = Array.isArray(answer)
        ? answer.join('\n')
        : answer;

      setMessages((prev) => [
        ...prev.slice(0, -1),
        {
          message: <Markdown>{formattedAnswer}</Markdown>,
          sentTime: new Date().toISOString(),
          sender: 'AI'
        }
      ]);

      setIsProcessing(false);
      setAbortController(null);
    },
    [appliedFilters]
  );

  const sendMessage = async (question: string) => {
    if (question) {
      setMessages((prev) => [
        ...prev,
        {
          message: question,
          sentTime: new Date().toISOString(),
          sender: 'You'
        }
      ]);
      await askAI(question);
    }
  };

  const clearChat = useCallback(() => {
    setMessages([]);
    setIsProcessing(false);
    if (abortController) {
      abortController.abort();
      setAbortController(null);
    }
  }, [abortController]);

  const insights = useMemo(() => {
    if (typeof insightsResponse === 'string') {
      return <Markdown className="text-left">{insightsResponse}</Markdown>;
    }
    if (insightsResponse) {
      return <div className="flex flex-col">{list(insightsResponse)}</div>;
    }
    return null;
  }, [insightsResponse]);

  const reportSummary = useMemo(() => {
    if (
      typeof insightsSummaryResponse === 'string' &&
      insightsSummaryResponse!.length !== 0
    ) {
      return (
        <Markdown className="text-left">{insightsSummaryResponse}</Markdown>
      );
    }
    if (insightsSummaryResponse) {
      return (
        <div className="flex flex-col">{list(insightsSummaryResponse)}</div>
      );
    }
    return null;
  }, [insightsSummaryResponse]);

  const insightsReport = useMemo(() => {
    if (
      typeof insightsReportResponse === 'string' &&
      insightsReportResponse!.length !== 0
    ) {
      return (
        <Markdown className="text-left">{insightsReportResponse}</Markdown>
      );
    }
    if (insightsReportResponse) {
      return (
        <div className="flex flex-col">{list(insightsReportResponse)}</div>
      );
    }
    return null;
  }, [insightsReportResponse]);

  return {
    messages,
    setMessages,
    insights,
    isInsightsLoading,
    refetchInsights,
    insightsReport,
    isInsightsReportLoading,
    refetchReportInsights,
    reportSummary,
    isReportSummaryLoading,
    refetchReportSummary,
    sendMessage,
    clearChat,
    isProcessing,
    setIsProcessing
  };
};

export default useInsights;
