import React, { useMemo } from 'react';
import { IDataSchema, IFile, ISpaceCreator } from '@all-types/csat-types';
import { Button, ButtonVariant } from '@/components/ui/button';
import InputDialog from '@ui/dialogs/InputDialog';
import Spinner from '@ui/layout/Spinner';
import ConfirmationModal from '@ui/dialogs/ConfirmationModal';
import SpaceParticipants from '@ui/space/SpaceParticipants';
import { MdOutlineAddCircleOutline } from 'react-icons/md';

interface ISpacesTab {
  isSpaceOwner: boolean;
  title?: string;
  type?: string;
  dataSchema?: IDataSchema | null;
  files?: IFile[] | null;
  isLoading?: boolean;
  onDelete?: () => void;
  owner?: ISpaceCreator | null;
  participants?: string[] | null;
  onRemoveParticipant: (email: string) => void;
  onAddParticipant: (email: string) => Promise<boolean>;
}
const SpaceTab = ({
  owner,
  participants,
  title = 'Default',
  type,
  dataSchema,
  files,
  isLoading = true,
  onDelete = () => {},
  onRemoveParticipant,
  onAddParticipant,
  isSpaceOwner
}: ISpacesTab) => {
  const deleteSpaceButton = useMemo(() => {
    const isDefault = title === 'Default';
    return (
      !isDefault &&
      isSpaceOwner && (
        <ConfirmationModal
          hideRemoveLabel={true}
          title={`Are you sure you want to delete this space "${title}"?`}
          onDelete={onDelete}
        />
      )
    );
  }, [title, isSpaceOwner, onDelete]);

  return (
    <div>
      <div className="flex justify-between px-5 pt-5 pb-3 items-center">
        <span className="text-2xl justify-start flex ">
          Space &#8220;<span className="text-darkCyan">{title}</span>
          &#8221;
        </span>
        <div>{deleteSpaceButton}</div>
      </div>
      <div className="w-[calc(100%-40px)] border-t border-gray-200 ml-5"></div>
      <div className="grid py-4 text-left px-5 !w-full h-full">
        {!isLoading ? (
          <div>
            {owner && (
              <div className="pb-4">
                <span className="font-bold">
                  Owner:&nbsp;
                  <span className="font-semibold text-gray-500">
                    {owner.email} (
                    <span className="text-darkCyan">{owner.name}</span>)
                  </span>
                </span>
              </div>
            )}
            <div className="pb-4">
              <div>
                <div className="flex flex-wrap gap-1 mb-2 items-center">
                  <SpaceParticipants
                    participants={participants}
                    onRemoveParticipant={onRemoveParticipant}
                    remove={true}
                  />
                </div>
                <InputDialog
                  title="Add new participant"
                  inputPlaceholder="Enter user email"
                  buttonTitle="Add"
                  onApply={onAddParticipant}
                  maxCharacters={30}
                  isEmail={true}>
                  <Button
                    variant={ButtonVariant.SECONDARY}
                    className="px-2 !h-11 border text-darkCyan border-main cursor-pointer rounded-md font-semibold text-sm">
                    <MdOutlineAddCircleOutline /> Add participant
                  </Button>
                </InputDialog>
              </div>
            </div>
            <div className="pb-4">
              <span className="font-bold">File schema:&nbsp;</span>
              {dataSchema && Object.keys(dataSchema).length > 0 ? (
                <div className="font-semibold text-gray-500 ml-4">
                  {dataSchema.fields.map((field) => field.title).join(', ')}
                </div>
              ) : (
                <span className="text-darkCyan">
                  Space doesn&apos;t have schema yet.
                </span>
              )}
            </div>
            {(type === 'File' || !type) && (
              <div className="pb-4">
                <span className="font-bold">Files:&nbsp;</span>
                {files && files.length > 0 ? (
                  <ul className="ml-4">
                    {files.map((file, index) => (
                      <li key={index}>&#8226;&nbsp;{file.fileName}</li>
                    ))}
                  </ul>
                ) : (
                  <span className="text-darkCyan">No uploaded files</span>
                )}
              </div>
            )}
            <span>
              <span className="font-bold">Type:&nbsp;</span>
              {!type ? (
                <span className="text-gray-500 font-bold">None</span>
              ) : type === 'File' ? (
                <span className="text-gray-500 font-bold">
                  {type} (<span className="text-darkCyan">{files?.length}</span>
                  )
                </span>
              ) : (
                <span className="text-gray-500 font-bold">
                  Integration (<span className="text-darkCyan">{type}</span>)
                </span>
              )}
            </span>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

export default SpaceTab;
