import React, { FC } from 'react';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import TooltipWrapper from '@ui/tooltip/TooltipWrapper';
import SpaceActions from '@ui/space/SpaceActions';

interface ISpaceButtonProps {
  name: string;
  type: string;
  onClick: () => void;
  isCurrent: boolean;
  totalFiles?: number;
  onInfoOpen: () => void;
  onAddParticipant: (email: string) => Promise<boolean>;
  title: string;
  onDelete: () => void;
  onSelectSpace: () => void;
}

const SpaceButton: FC<ISpaceButtonProps> = ({
  name,
  type,
  onClick,
  isCurrent = false,
  totalFiles = 0,
  onInfoOpen,
  onAddParticipant,
  onDelete,
  title,
  onSelectSpace
}) => {
  return (
    <div className="relative bg-white border-none sm:min-w-56 md:min-w-72 min-w-1/2 font-inter aspect-square border-2 border-gray-300 rounded-lg flex justify-center items-center hover:opacity-100 overflow-x-hidden">
      <div className="flex flex-col break-words">
        <div>
          {isCurrent && (
            <Badge className="absolute top-3 left-3 text-sm" variant="chichi">
              Current
            </Badge>
          )}
          <div className="absolute top-2 right-1">
            <SpaceActions
              onInfoOpen={onInfoOpen}
              onAddParticipant={onAddParticipant}
              title={title}
              onDelete={onDelete}
              onSelectSpace={onSelectSpace}
            />
          </div>
          <span className="absolute bottom-14 left-6 text-gray-700 font-bold text-base">
            Type:&nbsp;
            {!type ? (
              <span className="font-normal">None</span>
            ) : type === 'File' ? (
              <span className="font-normal">
                {type} <span className="text-gray-300">({totalFiles})</span>
              </span>
            ) : (
              <span className="font-normal">
                Integration <span className="text-gray-300">({type})</span>
              </span>
            )}
          </span>
          {!isCurrent && (
            <Button
              className="absolute bottom-3 right-3 bg-main font-semibold font-sans text-sm"
              onClick={onClick}
              disabled={isCurrent}>
              Switch
            </Button>
          )}
        </div>
        <TooltipWrapper text={name}>
          <div className="text-2xl text-primary-25 font-medium mx-4 w-64">
            {name.length > 60 ? `${name.slice(0, 60)}...` : name}
          </div>
        </TooltipWrapper>
      </div>
    </div>
  );
};

export default SpaceButton;
