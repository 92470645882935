import React from 'react';
import { MessageType } from '@hooks/useInsights';
import ChatNavigationHeader from '@ui/chat/ChatNavigationHeader';
import ChatLayout from '@ui/chat/ChatLayout';
import ChatWrapper from '@ui/chat/ChatWrapper';
import ChatInput from '@ui/chat/ChatInput';

interface IAskAiChat {
  messages: MessageType[];
  hasSentMessage: boolean;
  setHasSentMessage: (value: boolean) => void;
  sendMessage: (question: string) => Promise<void>;
  isInsightsLoading: boolean;
  clearChat: () => void;
  isProcessing?: boolean;
}

const AskAiChat = ({
  messages,
  hasSentMessage,
  sendMessage,
  setHasSentMessage,
  isInsightsLoading,
  clearChat,
  isProcessing = false
}: IAskAiChat) => {
  const handleQuestionSelect = async (selectedQuestion: string) => {
    setHasSentMessage(true);
    await sendMessage(selectedQuestion);
  };

  return (
    <div className="w-1/2 gap-4 pl-8 h-full">
      <ChatNavigationHeader
        clearChat={clearChat}
        messages={messages}
        hasSentMessage={hasSentMessage}
        setHasSentMessage={setHasSentMessage}
      />
      {!hasSentMessage ? (
        <ChatLayout
          disabled={isProcessing}
          onQuestionSelect={handleQuestionSelect}
        />
      ) : (
        <ChatWrapper messages={messages} />
      )}
      <ChatInput
        setHasSentMessage={setHasSentMessage}
        sendMessage={sendMessage}
        disabled={isProcessing}
      />
    </div>
  );
};

export default AskAiChat;
