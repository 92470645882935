import React, { useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { SpaceContext, SpaceContextType } from '@context/contexts';
import SpaceAPI from '@api/spaceAPI';
import SpaceButton from '@ui/space/SpaceButton';
import SpaceInfoDialog from '@ui/dialogs/SpaceInfoDialog';
import useSpaceSettings from '@hooks/useSpaceSettings';
import AddSpaceButton from '@ui/space/AddSpaceButton';
import SpaceSettingsDialog from '@ui/dialogs/SpaceSettingsDialog';

const SpacesPage = () => {
  const spaceAPI = SpaceAPI.getInstance();

  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [selectedSpaceId, setSelectedSpaceId] = useState<string | null>(null);

  const { spaces, currentSpace, onCreateSpace, onSpaceChange, onDeleteSpace } =
    useContext<SpaceContextType>(SpaceContext);

  const { data: space, isLoading: isSpaceLoading } = useQuery({
    queryKey: ['space', selectedSpaceId],
    queryFn: () => spaceAPI.getSpace(selectedSpaceId!),
    enabled: !!selectedSpaceId
  });

  const { spaceSettings, onAddParticipant, isSpaceOwner } = useSpaceSettings({
    spaceId: selectedSpaceId,
    space
  });

  const onInfoOpen = (id: string) => {
    setIsInfoOpen(true);
    setSelectedSpaceId(id);
  };

  return (
    <div className="sm:w-full w-full py-5 pl-5  bg-gray-50">
      <div className="font-bold text-3xl pb-5 text-start">Spaces</div>
      {spaces.length > 0 && (
        <div className="grid gap-5 md:grid-cols-[repeat(auto-fill,_minmax(288px,_1fr))] sm:grid-cols-[repeat(auto-fill,_minmax(224px,_1fr))] w-full sm:pr-0 pr-5">
          <SpaceSettingsDialog
            title="Create new space"
            inputPlaceholder="Enter space name"
            buttonTitle="Create"
            onApply={onCreateSpace}
            maxCharacters={30}>
            <AddSpaceButton name="+ New" />
          </SpaceSettingsDialog>
          {spaces.map((space, index) => (
            <SpaceButton
              onInfoOpen={() => onInfoOpen(space.id)}
              key={index}
              totalFiles={space.totalFiles}
              name={space.name}
              onClick={() => onSpaceChange(space.id)}
              isCurrent={space.id === currentSpace?.id}
              type={space.type}
              onAddParticipant={onAddParticipant}
              onDelete={() => onDeleteSpace(space.id)}
              title={space.name}
              onSelectSpace={() => setSelectedSpaceId(space.id)}
            />
          ))}
          {space && (
            <SpaceInfoDialog
              isOpen={isInfoOpen}
              isSpaceOwner={isSpaceOwner}
              onOpenChange={setIsInfoOpen}
              title={space.name}
              type={space.type}
              files={space.files}
              dataSchema={space.dataSchema}
              isLoading={isSpaceLoading}
              owner={spaceSettings?.creator}
              participants={spaceSettings?.userEmails}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SpacesPage;
