import React, { useContext, useEffect } from 'react';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import ProfileTab from '@ui/settings-tabs/ProfileTab';
import { useAuth0 } from '@auth0/auth0-react';
import { SpaceContext, SpaceContextType } from '@context/contexts';
import { useQuery } from '@tanstack/react-query';
import SpaceAPI from '@api/spaceAPI';
import SpaceTab from '@ui/settings-tabs/SpaceTab';
import useSpaceSettings from '@hooks/useSpaceSettings';
import Spinner from '@ui/layout/Spinner';
import { MdOutlineSettings } from 'react-icons/md';
import { IoIosContact } from 'react-icons/io';
import { SiNintendogamecube } from 'react-icons/si';
import { useNavigate, useParams } from 'react-router-dom';

const triggers = [
  {
    value: 'space',
    label: 'Space',
    icon: SiNintendogamecube,
    disabled: false
  },
  {
    value: 'profile',
    label: 'Profile',
    icon: IoIosContact,
    disabled: false
  },
  {
    value: 'general',
    label: 'General',
    icon: MdOutlineSettings,
    disabled: true
  }
];

const defaultSubpage = 'space';

const SettingsPage = () => {
  const { user } = useAuth0();
  const spaceAPI = SpaceAPI.getInstance();
  const navigate = useNavigate();
  const { '*': subpage } = useParams<{ '*': string }>();

  const { currentSpace, onDeleteSpace } =
    useContext<SpaceContextType>(SpaceContext);
  const currentSpaceId = currentSpace?.id as string;

  const { data: space, isLoading: isSpaceLoading } = useQuery({
    queryKey: ['space', currentSpaceId],
    queryFn: () => spaceAPI.getSpace(currentSpaceId!),
    enabled: !!currentSpaceId
  });
  const { spaceSettings, onAddParticipant, onRemoveParticipant, isSpaceOwner } =
    useSpaceSettings({ spaceId: currentSpaceId, space });

  const navigateToSubpage = (value: string) => {
    navigate(`/settings/${value}`);
  };

  const onSpaceDelete = async (id: string) => {
    await onDeleteSpace(id);
  };

  useEffect(() => {
    if (!subpage) {
      navigate(`/settings/${defaultSubpage}`);
    }
  }, [subpage, navigate]);

  return (
    <div className="w-full">
      <Tabs
        className="flex gap-5 flex-row w-full"
        defaultValue={defaultSubpage}
        value={subpage || defaultSubpage}>
        <TabsList className="flex flex-col mt-5 ml-5 bg-white h-fit !text-left !justify-start">
          {triggers.map((trigger) => (
            <TabsTrigger
              key={trigger.value}
              value={trigger.value}
              onClick={() => navigateToSubpage(trigger.value)}
              disabled={trigger.disabled}
              className="h-10 !w-72 data-[state=active]:bg-lightCyan hover:bg-gray-100 data-[state=active]:hover:bg-gray-100 flex justify-start">
              <trigger.icon size={20} />
              <span className="pl-2">{trigger.label}</span>
            </TabsTrigger>
          ))}
        </TabsList>
        <TabsContent value="profile" className="mt-5 w-full bg-white mb-5">
          <ProfileTab
            email={user?.email}
            verified={user?.email_verified}
            name={user?.name}
            nickname={user?.nickname}
            picture={user?.picture}
          />
        </TabsContent>
        <TabsContent value="space" className="mt-5 w-full bg-white mb-5">
          {space ? (
            <SpaceTab
              isSpaceOwner={isSpaceOwner}
              title={space.name}
              type={space.type}
              files={space.files}
              dataSchema={space.dataSchema}
              isLoading={isSpaceLoading}
              owner={spaceSettings?.creator}
              participants={spaceSettings?.userEmails}
              onRemoveParticipant={onRemoveParticipant}
              onAddParticipant={onAddParticipant}
              onDelete={() => onSpaceDelete(space.id)}
            />
          ) : (
            <Spinner />
          )}
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default SettingsPage;
