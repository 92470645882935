import React from 'react';
import { cn } from '@/lib/utils';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import { Button, ButtonVariant } from '@/components/ui/button';
import { MdDelete } from 'react-icons/md';

interface IConfirmationModalProps {
  title?: string;
  onDelete: () => void;
  hideRemoveLabel?: boolean;
}

const ConfirmationModal = ({
  title = 'Default',
  onDelete,
  hideRemoveLabel = false
}: IConfirmationModalProps) => {
  return (
    <Dialog>
      <DialogTrigger
        className={cn(
          'flex items-center text-red-500 font-normal text-sm px-2 h-10 w-full',
          {
            'hover:bg-gray-100': !hideRemoveLabel
          }
        )}>
        <MdDelete
          size={hideRemoveLabel ? 40 : 18}
          className={cn({
            'rounded-md hover:bg-gray-100 p-2': hideRemoveLabel
          })}
        />
        {!hideRemoveLabel && <span className="break-all ml-1">Delete</span>}
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle className="text-md">{title}</DialogTitle>
        </DialogHeader>
        <DialogFooter>
          <DialogClose className="flex gap-3">
            <Button variant={ButtonVariant.OUTLINE}>Cancel</Button>
            <Button onClick={onDelete}>Delete</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationModal;
