import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { cn } from '@/lib/utils';
import CsatAPI from '@api/csatAPI';
import CsatTranscription from '@ui/csat-transcription/CSATTranscription';
import AnalysisFilters from '@ui/filters/AnalysisFilters';
import { IFilters } from '@all-types/csat-types';
import { extractExistingParams } from '@utils/urlHelper';
import { SpaceContext, SpaceContextType } from '@context/contexts';
import Spinner from '@ui/layout/Spinner';
import SkeletonGroup from '@ui/layout/SkeletonGroup';
import useProcessStatus from '@hooks/useProcessStatus';
import { changeDatesFormat } from '@utils/time';
import StatusBox from '@ui/status/StatusBox';
import { parseCategories } from '@utils/checkboxHelper';

const FeedbackAnalysisPage = () => {
  const csatAPI = CsatAPI.getInstance();

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [resultsCount, setResultsCount] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const {
    feedbackStatus,
    isReady,
    triggerRefetch,
    integrationStatus,
    isFailed,
    isRunning,
    isSuccessful
  } = useProcessStatus({
    isConnected: true
  });
  const { dataSchema, currentSpace } =
    useContext<SpaceContextType>(SpaceContext);
  const currentSpaceId = currentSpace?.id;
  const dateSchema = dataSchema.date;

  const updateResultsCount = (count: number) => {
    setResultsCount(count);
  };

  const appliedFilters = useMemo(() => {
    const params = extractExistingParams(searchParams);
    if (params.startDate) {
      params.startDate = Number(params.startDate);
    }
    if (params.endDate) {
      params.endDate = Number(params.endDate);
    }

    return params;
  }, [searchParams]);

  const filtersToSend = useMemo(() => {
    const selectedFilters = { ...appliedFilters };
    if (selectedFilters.startDate) {
      delete selectedFilters.startDate;
    }
    if (selectedFilters.endDate) {
      delete selectedFilters.endDate;
    }
    const dates = {
      startDate: appliedFilters.startDate,
      endDate: appliedFilters.endDate
    };
    const formattedDates = changeDatesFormat(dates, dateSchema);
    if (selectedFilters.categories?.length) {
      const categories = parseCategories(selectedFilters.categories);
      return {
        ...selectedFilters,
        categories
      };
    }
    return { ...selectedFilters, ...formattedDates };
  }, [appliedFilters, dateSchema]);

  const toggleFilters = () => {
    setIsFiltersOpen(!isFiltersOpen);
  };

  const {
    data: filters,
    isLoading: isFiltersLoading,
    refetch: refetchFilters
  } = useQuery({
    queryKey: ['filters', currentSpaceId],
    queryFn: () => csatAPI.getFilters(currentSpaceId as string),
    enabled: !!currentSpaceId
  });

  const refetchAll = async () => {
    await refetchFilters();
  };

  useEffect(() => {
    if (isReady) {
      refetchAll();
    }
  }, [isReady]);

  const onSubmit = (data: Partial<IFilters>) => {
    setSearchParams(data);
  };

  const backToCsatPage = (): void => {
    navigate('/csat');
  };

  return (
    <div className="relative w-full h-full pt-5 md:pl-5 sm:pl-5 pl-0 md:pr-0 pr-0 flex items-start gap-5 pb-5">
      <div
        className={cn(
          'px-5 sm:px-0 sm:w-[240px] md:w-[260px] w-full mt-0 sm:top-[92px] top-6 overflow-hidden z-10 ',
          {
            'absolute flex sm:flex sm:sticky': isFiltersOpen,
            'hidden sm:flex sm:sticky': !isFiltersOpen
          }
        )}>
        {(isFiltersLoading || (isRunning && !isReady)) && (
          <SkeletonGroup count={6} />
        )}
        {filters && isReady && (
          <AnalysisFilters
            filters={filters}
            applied={appliedFilters}
            onSubmit={onSubmit}
            resultsCount={resultsCount}
          />
        )}
      </div>
      <div className="md:w-[calc(100%-280px)] sm:w-[calc(100%-260px)] h-full">
        {isReady ? (
          <CsatTranscription
            feedbackStatus={feedbackStatus}
            toggleFilters={toggleFilters}
            id={id as string}
            appliedFilters={filtersToSend}
            onBackClick={backToCsatPage}
            triggerRefetch={triggerRefetch}
            updateResultsCount={updateResultsCount}
            integrationStatus={integrationStatus}
            isFailed={isFailed}
            isRunning={isRunning}
            isReady={isReady}
            isSuccessful={isSuccessful}
          />
        ) : (
          <div className="flex justify-center items-center h-full w-full">
            {isRunning ? (
              <div className="bg-white w-fit h-fit py-5 px-12 flex items-center flex-col">
                <StatusBox
                  integrationStatus={integrationStatus}
                  feedbackStatuses={feedbackStatus}
                />
                <Spinner classNameWrapper="h-8 w-8 pt-2" />
              </div>
            ) : (
              <Spinner />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FeedbackAnalysisPage;
