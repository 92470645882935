import React, { FC, useEffect, useMemo, useState } from 'react';
import { Input } from '@/components/ui/input';
import { TrashIcon } from '@icons';
import { Button, ButtonVariant } from '@/components/ui/button';
import { MdOutlineAddCircleOutline } from 'react-icons/md';
import { ICustomCategories } from '@all-types/csat-types';

interface ISpaceCategorySettingsProps {
  onChangeCategories: (value: ICustomCategories) => void;
  customCategories?: string[];
}

type Category = {
  key: number;
  value: string;
};

const SpaceCategorySettings: FC<ISpaceCategorySettingsProps> = ({
  onChangeCategories,
  customCategories = []
}) => {
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    if (customCategories.length !== 0) {
      setCategories(
        customCategories.map((category, index) => ({
          key: new Date().getTime() * index,
          value: category
        }))
      );
    }
  }, [customCategories]);

  const onAddCategory = () => {
    const newCategory = { key: new Date().getTime(), value: '' };
    setCategories([...categories, newCategory]);
  };

  const handleCategoryChange = (key: number, value: string) => {
    setCategories(
      categories.map((category) =>
        category.key === key ? { ...category, value } : category
      )
    );
  };

  useEffect(() => {
    const categoriesToAdd = categories
      .filter((category) => customCategories.indexOf(category.value) === -1)
      .map((category) => category.value);
    const categoriesToRemove = customCategories.filter(
      (category) => categories.findIndex((c) => c.value === category) === -1
    );
    onChangeCategories({
      addCategories: categoriesToAdd,
      removeCategories: categoriesToRemove
    });
  }, [categories]);

  const onRemoveCategory = (key: number) => {
    setCategories(categories.filter((category) => category.key !== key));
  };

  const isAddButtonDisabled = useMemo(
    () => categories.some((category) => category.value === ''),
    [categories]
  );

  return (
    <div className="flex items-center space-x-2">
      <div className="flex flex-col justify-between gap-4">
        {categories.map((category) => (
          <div className="flex justify-between gap-4" key={category.key}>
            <Input
              value={category.value}
              onChange={(event) =>
                handleCategoryChange(category.key, event.target.value)
              }
            />
            <div
              className="hover:bg-lightCyan hover:cursor-pointer p-2 rounded-md"
              onClick={() => onRemoveCategory(category.key)}>
              <TrashIcon />
            </div>
          </div>
        ))}
        <Button
          variant={ButtonVariant.SECONDARY}
          className="px-2 !h-11 border text-darkCyan border-main cursor-pointer rounded-md font-semibold text-sm"
          onClick={onAddCategory}
          disabled={isAddButtonDisabled}>
          <MdOutlineAddCircleOutline /> Add category
        </Button>
      </div>
    </div>
  );
};

export default SpaceCategorySettings;
