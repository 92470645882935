import React, { FC, useContext, useMemo, useState } from 'react';
import { Button, ButtonVariant } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import { cn } from '@/lib/utils';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '@/components/ui/accordion';
import SpaceCategorySettings from '@ui/space/SpaceCategorySettings';
import { ICustomCategories, ISpace } from '@all-types/csat-types';
import { SpaceContext, SpaceContextType } from '@context/contexts';

interface INameDialogProps {
  onApply: (value: string) => Promise<ISpace | null>;
  defaultValue?: string;
  title?: string;
  description?: string;
  buttonTitle?: string;
  inputPlaceholder?: string;
  maxCharacters?: number;
  children: React.ReactNode;
}

const SpaceSettingsDialog: FC<INameDialogProps> = ({
  onApply,
  defaultValue = '',
  children,
  buttonTitle = 'OK',
  title = 'Name dialog',
  description = '',
  maxCharacters = Infinity,
  inputPlaceholder = 'Enter name'
}) => {
  const [value, setValue] = useState(defaultValue);
  const [isOpen, setIsOpen] = useState(false);
  const [categories, setCategories] = useState<ICustomCategories | null>(null);

  const { onUpdateSpaceCategories } =
    useContext<SpaceContextType>(SpaceContext);

  const isMaxLengthReached = value.length >= maxCharacters;

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (newValue.length <= maxCharacters) {
      setValue(newValue);
    }
  };

  const onApplyClick = async () => {
    const spaceCreated = await onApply(value);
    if (
      spaceCreated?.id &&
      (categories?.addCategories.length || categories?.removeCategories.length)
    ) {
      await onUpdateSpaceCategories(spaceCreated.id, categories!);
    }
    setIsOpen(false);
  };

  const onOpenChange = () => {
    setValue(defaultValue);
    setIsOpen(!isOpen);
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && value.trim() !== '') {
      await onApplyClick();
    }
  };

  const onChangeCategories = (value: ICustomCategories) => {
    setCategories(value);
  };

  const isAddButtonDisabled = useMemo(
    () =>
      !value || categories?.addCategories.some((category) => category === ''),
    [value, categories]
  );

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <div className="grid flex-1 gap-2">
            <Label htmlFor="link" className="sr-only">
              Name
            </Label>
            <Input
              value={value}
              placeholder={inputPlaceholder}
              onChange={onInputChange}
              onKeyDown={handleKeyDown}
            />
            <span
              className={cn('text-gray-500 text-xs', {
                'text-main': isMaxLengthReached
              })}>
              {`Name is limited to ${maxCharacters} characters`}
            </span>
          </div>
        </div>
        <Accordion type="single" collapsible className="w-full">
          <AccordionItem value="item-1">
            <AccordionTrigger>Advanced settings</AccordionTrigger>
            <AccordionContent>
              <SpaceCategorySettings onChangeCategories={onChangeCategories} />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        <DialogFooter className="justify-end flex flex-row gap-2 items-end">
          <DialogClose asChild>
            <Button className="px-6" variant={ButtonVariant.SECONDARY}>
              Cancel
            </Button>
          </DialogClose>
          <DialogClose asChild disabled={isAddButtonDisabled}>
            <Button className="px-6" onClick={onApplyClick}>
              {buttonTitle}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SpaceSettingsDialog;
