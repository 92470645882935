// round number to 1 decimal place
export const getRoundNumber = (num: number | string): number => {
  let number = num;
  if (typeof number === 'string') {
    number = Number(number);
  }
  return Math.round((number + Number.EPSILON) * 10) / 10;
};

export const calculatePercentage = (value: number, total: number): number => {
  return getRoundNumber((value / total) * 100);
};
