import { IOption, ITree } from '@all-types/ui-types';

export const mapToOptions = (items: string[]) => {
  return items.map((item) => ({ id: item, label: item }));
};

export const mapCategoriesToOptions = (categories: ITree) => {
  return Object.entries(categories).map(([category, value]) => ({
    id: category,
    label: category,
    subOptions: value.map((subcategory) => ({
      id: subcategory,
      label: subcategory
    }))
  }));
};

export const parseCategories = (categories: string[]): ITree => {
  const categoryMap = categories.reduce(
    (acc: Map<string, string[]>, item: string) => {
      const [key, value] = item.split(' | ');
      let values: string[];
      if (!value) {
        values = [...(acc.get(key) || [])];
      } else {
        values = [...(acc.get(key) || []), value];
      }
      return acc.set(key, values);
    },
    new Map()
  );
  return Object.fromEntries(categoryMap);
};

export const transformCategories = (categories: ITree) => {
  const newValue: string[] = [];
  Object.entries(categories).forEach(([category, subcategories]) => {
    if (subcategories.length === 0) {
      newValue.push(category);
    } else {
      (subcategories as string[]).forEach((subcategory) => {
        newValue.push(`${category} | ${subcategory}`);
      });
    }
  });
  return { categories: newValue };
};

export const onCheckedChange = (
  checked: string | boolean,
  onChange: any,
  parentId: string,
  currValue: ITree,
  option: IOption
) => {
  if (checked) {
    const getAllOptions = () => {
      return option.subOptions?.map((item) => item.id) || [];
    };
    const getSubOptions = () => {
      return [...(currValue[parentId] || []), option.id];
    };
    const newValue = {
      [parentId || option.id]: parentId ? getSubOptions() : getAllOptions()
    };
    return onChange({ ...currValue, ...newValue });
  }

  let newValue = { ...currValue };
  if (parentId) {
    const filteredValues = currValue[parentId]?.filter(
      (value) => value != option.id
    );
    if (filteredValues?.length) {
      newValue = {
        ...currValue,
        [parentId]: filteredValues
      };
    } else {
      delete newValue[parentId];
    }
  } else {
    delete newValue[option.id];
  }

  return onChange(newValue);
};
