import React, { FC, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { MessageType } from '@hooks/useInsights';
import Message from '@ui/chat/Message';
import { Logo } from '@icons';

interface IChatWrapperProps {
  messages: MessageType[];
}

const ChatWrapper: FC<IChatWrapperProps> = ({ messages }) => {
  const chatRef = useRef<HTMLDivElement>(null);
  const { user } = useAuth0();

  useEffect(() => {
    // scroll to bottom of chat wrapper
    chatRef.current?.scrollTo(0, chatRef.current.scrollHeight);
  }, [messages]);

  return (
    <div className="h-[calc(100%-138px)] relative flex flex-col">
      <div
        className="flex flex-col gap-4 pr-4 w-full h-full overflow-y-scroll"
        ref={chatRef}>
        {messages.map((message) => (
          <Message
            key={message.sentTime}
            icon={
              message.sender === 'AI' ? (
                <Logo className="h-9 w-9 rounded-[100px] border-[2px] border-main" />
              ) : (
                <img
                  className="w-9 h-9 rounded-[100px] flex items-center"
                  src={user?.picture}
                  alt={user?.name}
                />
              )
            }
            name={message.sender}
            message={message.message}
          />
        ))}
      </div>
    </div>
  );
};

export default ChatWrapper;
