import React from 'react';
import { Badge } from '@/components/ui/badge';
import { CloseIcon } from '@icons';

interface ISpaceParticipants {
  participants?: string[] | null;
  remove?: boolean;
  onRemoveParticipant?: (email: string) => void;
}

const SpaceParticipants = ({
  participants,
  remove = false,
  onRemoveParticipant = () => {}
}: ISpaceParticipants) => {
  return (
    <div className="flex gap-2">
      <span className="font-normal flex flex-wrap gap-2 pb-2">
        {participants && participants.length > 0 ? (
          <>
            <span className="font-bold">Participants:</span>
            {participants.map((participant) => (
              <Badge key={participant} variant="chichi" className="h-[26px]">
                {participant}
                {remove && (
                  <CloseIcon
                    className="block hover:cursor-pointer ml-1"
                    aria-hidden="true"
                    onClick={() => {
                      onRemoveParticipant(participant);
                    }}
                  />
                )}
              </Badge>
            ))}
          </>
        ) : (
          <div className="flex gap-1">
            <span className="font-bold">Participants:</span>
            <span className="text-gray-500">No participants</span>
          </div>
        )}
      </span>
    </div>
  );
};

export default SpaceParticipants;
