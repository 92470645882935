import { createContext } from 'react';
import { ICustomCategories, IFileSchema, ISpace } from '@all-types/csat-types';

export type SpaceContextType = {
  spaces: ISpace[];
  refetchSpaces: () => Promise<any>;
  dataSchema: IFileSchema;
  fileFields: string[];
  onSpaceChange: (spaceId: string) => void;
  onCreateSpace: (spaceName: string) => Promise<ISpace | null>;
  onDeleteSpace: (spaceId: string) => Promise<boolean>;
  currentSpace?: ISpace | null;
  refetchCurrentSpace: () => Promise<any>;
  onUpdateSpaceCategories: (
    spaceId: string,
    categories: ICustomCategories
  ) => Promise<boolean>;
};

export const SpaceContext = createContext<SpaceContextType>(null!);
