import { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { startOfDay, subMonths } from 'date-fns';
import IntercomAPI from '@api/intercomAPI';
import { SpaceContext, SpaceContextType } from '@context/contexts';
import useProcessStatus from '@hooks/useProcessStatus';

const defaultStartDate = subMonths(new Date(), 1);

const useIntegration = () => {
  const intercomAPI = IntercomAPI.getInstance();

  const [processingStarted, setProcessingStarted] = useState(false);

  const { user } = useAuth0();
  const { currentSpace, refetchCurrentSpace, refetchSpaces } =
    useContext<SpaceContextType>(SpaceContext);
  const currentSpaceId = currentSpace?.id;

  const [isConnected, setConnected] = useState(false);

  const {
    startFetchingProcessStatus,
    feedbackStatus,
    Pending: isPending,
    integrationStatus,
    isRunning,
    isReady,
    isFailed,
    isSuccessful
  } = useProcessStatus({
    isConnected
  });

  const checkIntegrationToken = (): boolean => {
    const intercomToken = localStorage.getItem('intercomToken');
    setConnected(!!intercomToken);
    return !!intercomToken;
  };

  useEffect(() => {
    checkIntegrationToken();

    if (currentSpaceId) {
      const checkIfIntercomConnected = async (e: StorageEvent) => {
        if (e.key === 'intercomToken') {
          checkIntegrationToken();
        }
      };

      window.addEventListener('storage', checkIfIntercomConnected);

      return () => {
        window.removeEventListener('storage', checkIfIntercomConnected);
      };
    }
  }, [currentSpaceId]);

  const syncIntegrationData = async (date: Date) => {
    console.log('start date', date);
    await intercomAPI.syncData(currentSpaceId!, startOfDay(date).getTime());
  };

  const onConnect = () => {
    window.open(
      intercomAPI.getAuthURL(user?.sub as string),
      '_blank',
      'noopener'
    );
  };

  const onStartProcessing = async (startDate?: Date) => {
    setProcessingStarted(true);
    await syncIntegrationData(startDate || defaultStartDate);
    await refetchCurrentSpace();
    await refetchSpaces();
    startFetchingProcessStatus();
  };

  const allowProcessing = !isRunning && !isReady && !processingStarted;

  return {
    allowProcessing,
    feedbackStatus,
    onConnect,
    isConnected,
    isPending,
    isRunning,
    isFailed,
    isSuccessful,
    integrationStatus,
    onStartProcessing
  };
};

export default useIntegration;
