const mapArray = (value: any[]): string[] => {
  const values: string[] = [];
  value.forEach((value) => {
    if (typeof value === 'string') {
      values.push(value);
    } else if (Array.isArray(value)) {
      // eslint-disable-next-line no-use-before-define
      values.push(...getValueByType(value));
    } else if (typeof value === 'object') {
      // eslint-disable-next-line no-use-before-define
      values.push(...getValueByType(value));
    }
  });
  return values;
};

export const getValueByType = (value: any): string[] => {
  if (typeof value === 'string') {
    return [value];
  }
  if (Array.isArray(value)) {
    return mapArray(value);
  }
  if (typeof value === 'object') {
    const objectValues = Object.values(value);
    return mapArray(objectValues);
  }
  return [];
};

export function isObject(value: unknown): boolean {
  return value !== null && typeof value === 'object' && !Array.isArray(value);
}
