import {
  IScore,
  IScoreResponse,
  ISentimentByData
} from '@all-types/csat-types';
import { getRoundNumber } from '@utils/numbers';
import { SENTIMENT_COLORS } from '@utils/chartHelper';

export const getChartData = (scoreData: IScore) => {
  return (
    scoreData &&
    Object.entries(scoreData)
      .filter(([key]) => key !== 'null')
      .map(([key, value]: any) => {
        return {
          name: key,
          data: [getRoundNumber(value.avg)]
        };
      })
  );
};

export const getTopicChartData = (data?: IScoreResponse | null) => {
  return (
    data &&
    Object.entries(data.distribution)
      .filter(([key]) => key !== 'null')
      .map(([key, value]: any) => {
        return {
          name: key,
          data: [value.value]
        };
      })
  );
};

export const getPieChartData = (scoreData: ISentimentByData) => {
  return (
    scoreData &&
    Object.entries(scoreData)
      .filter(([key]) => key !== 'null')
      .map(([key, value]: any) => {
        return {
          name: key,
          y: getRoundNumber(value.value),
          color: SENTIMENT_COLORS[key]
        };
      })
  );
};
