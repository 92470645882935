import React, { FC, useMemo, useState } from 'react';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '@/components/ui/accordion';
import { Input } from '@/components/ui/input';
import CheckboxGroup from '@ui/forms/CheckboxGroup';

interface IFilterSectionProps {
  title: string;
  value: string;
  values: string[];
  onChangeFilters: (items: string[]) => void;
  defaultValues?: string[];
  withSearch?: boolean;
}

const mapToOptions = (items: string[]) => {
  return items.map((item) => ({ id: item, label: item }));
};

const FilterSection: FC<IFilterSectionProps> = ({
  title,
  value,
  values,
  defaultValues,
  onChangeFilters,
  withSearch = false
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const options = useMemo(() => mapToOptions(values), [values]);

  const filteredOptions = useMemo(() => {
    return options.filter((option) =>
      option.id.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [options, searchTerm]);

  return (
    <AccordionItem value={value}>
      <AccordionTrigger className="text-darkCyan font-medium">
        {title}
      </AccordionTrigger>
      <AccordionContent>
        {withSearch && (
          <Input
            className="h-8 mb-4"
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        )}
        <CheckboxGroup
          options={filteredOptions}
          onChange={({ items }) => onChangeFilters(items)}
          defaultValues={defaultValues}
          withSubmitButton={false}
        />
      </AccordionContent>
    </AccordionItem>
  );
};

export default FilterSection;
