import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Link,
  LinkProps,
  Outlet,
  useLocation,
  useNavigate
} from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { cn } from '@/lib/utils';
import { LogoText } from '@icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import useOutsideClick from '@hooks/useOutsideClick';
import Selector from '@ui/selector/Selector';
import { SpaceContext, SpaceContextType } from '@context/contexts';
import SidebarMenu, { menu } from '@ui/layout/sidebarMenu/SidebarMenu';
import Notifications from '@ui/notifications/Notifications';

const WITH_SIDEBAR = false;

const disabledLinkProps: Partial<LinkProps> = {
  style: { pointerEvents: 'none', textDecoration: 'none', opacity: 0.5 },
  onClick: (event) => event.preventDefault()
};

const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth0();
  const [mobileMenu, setMobileMenu] = useState(false);

  const { spaces, currentSpace, onSpaceChange } =
    useContext<SpaceContextType>(SpaceContext);

  const headerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  useOutsideClick([headerRef, menuRef], () => {
    if (mobileMenu) {
      setMobileMenu(false);
    }
  });

  useEffect(() => {
    const title = menu.find((item) =>
      item.path.includes(location.pathname.split('/')[1])
    )?.title;
    document.title = title || 'Unwink';
  }, [location.pathname]);

  const onChangeSpace = (spaceId: string) => {
    onSpaceChange(spaceId);
    navigate('/');
  };
  const { logout } = useAuth0();

  const logoutWithRedirect = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    });
    localStorage.removeItem('auth-token');
    localStorage.removeItem('currentSpaceId');
  };

  const spaceOptions = useMemo(
    () =>
      spaces?.map((space) => ({
        label: space.name,
        value: space.id
      })) || [],
    [spaces]
  );

  const handleAnalysisClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (currentSpace?.type === 'File' || !currentSpace?.type) {
      navigate('/csat');
    } else if (currentSpace?.type === 'Intercom') {
      navigate(`/csat/${currentSpace.id}`);
    }
  };

  return (
    <>
      <div
        className="px-4 flex flex-row py-0 sm:px-12 justify-between w-screen fixed z-50 h-[72px] bg-white"
        ref={headerRef}>
        <div className="pl-2 sm:pl-0">
          <button
            className="w-6 h-6 flex flex-col justify-between cursor-pointer focus:outline-none sm:hidden"
            onClick={() => setMobileMenu(!mobileMenu)}>
            <span
              className={`w-full h-0.5 bg-black transition-transform transform ${
                mobileMenu ? 'rotate-45 translate-y-[11px]' : ''
              }`}></span>
            <span
              className={`w-full h-0.5 bg-black transition-opacity ${
                mobileMenu ? 'opacity-0' : ''
              }`}></span>
            <span
              className={`w-full h-0.5 bg-black transition-transform transform ${
                mobileMenu ? '-rotate-45 -translate-y-[11px]' : ''
              }`}></span>
          </button>
        </div>
        <div className="w-auto flex justify-center items-center">
          <Link
            to="/"
            className="flex flex-row gap-1 items-center justify-center sm:justify-start flex-auto cursor-pointer">
            <LogoText className="origin-left duration-300 h-[32px] !px-0" />
          </Link>
        </div>
        <div className="hidden sm:flex sm:justify-end sm:gap-20 sm:flex-row sm:flex-auto">
          <div className="flex justify-between gap-4 items-center">
            {!WITH_SIDEBAR && (
              <Link
                to="#"
                onClick={handleAnalysisClick}
                className="px-4 cursor-pointer flex flex-row hover:opacity-60 font-semibold text-sm ">
                Analysis
              </Link>
            )}
            <Link
              to="/integrations"
              className="px-4 cursor-pointer flex flex-row hover:opacity-60 font-semibold text-sm">
              Integrations
            </Link>
            <Link
              to="/spaces"
              className="px-4 cursor-pointer flex flex-row hover:opacity-60 font-semibold text-sm">
              Spaces
            </Link>
            <Link
              to="/settings"
              className="px-4 cursor-pointer flex flex-row hover:opacity-60 font-semibold text-sm">
              <span>Settings</span>
            </Link>
            {/* <Link
              to="/api"
              className="px-4 items-center cursor-pointer"
              {...disabledLinkProps}>
              API
            </Link>
            <Link
              to="/help"
              className="px-4 items-center cursor-pointer"
              {...disabledLinkProps}>
              Help
            </Link>*/}
            <Selector
              className="font-normal md:text-sm text-[10px] !h-[52px] mx-4 truncate ..."
              placeholder="Spaces"
              defaultValue={currentSpace?.id}
              onChange={onChangeSpace}
              options={spaceOptions}
            />
          </div>
        </div>
        <div className="flex items-center">
          <Notifications />
        </div>
        <div className="flex justify-end items-center">
          {isAuthenticated && (
            <DropdownMenu>
              <DropdownMenuTrigger className="w-[104px] sm:ml-4 h-12 flex text-xs font-medium rounded-[100px] focus:ring-0 focus-visible:outline-none focus-visible:ring-0">
                <div className="w-[104px] bg-gray-75 flex rounded-[100px] h-12 items-center px-2">
                  <div className="flex justify-end items-center">
                    Hello, {user?.name?.split(' ')[0]}
                  </div>
                  <div className="relative w-10 h-8">
                    <img
                      className="w-8 h-8 rounded-[100px] flex items-center"
                      src={user?.picture}
                      alt={user?.name}
                    />
                  </div>
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuLabel>{user?.email}</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem>
                  <Link to="/settings" className="w-full">
                    Settings
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem disabled>
                  <Link
                    to="/payment-plans"
                    className="w-full"
                    {...disabledLinkProps}>
                    Payment plans
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem disabled>
                  <Link to="/export" className="w-full" {...disabledLinkProps}>
                    Export data
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <div
                    onClick={logoutWithRedirect}
                    className="cursor-pointer w-full">
                    Logout
                  </div>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      </div>
      {mobileMenu && (
        <div className="sm:hidden">
          <div
            ref={menuRef}
            className={cn(
              mobileMenu ? 'flex' : 'hidden',
              'mt-[80px] w-screen absolute z-50 flex-col items-center self-end py-8 space-y-6 font-bold sm:w-auto left-0 right-0 dark:text-white bg-gray-50 dark:bg-slate-800 drop-shadow md rounded-xl'
            )}>
            {menu.slice(0, 3 - 7).map((menuItem, index) => (
              <Link
                to={menuItem.path}
                key={index}
                onClick={() => setMobileMenu(false)}>
                <span
                  className={cn(
                    location.pathname === menuItem.path && 'bg-lightCyan',
                    'p-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700'
                  )}>
                  {menuItem.title}
                </span>
              </Link>
            ))}
          </div>
        </div>
      )}
      <div
        className={cn(
          'min-h-screen flex flex-row pt-[72px] sm:pr-5 relative bg-gray-50',
          {
            'pl-[84px]': WITH_SIDEBAR,
            'overflow-hidden h-screen pointer-events-none': mobileMenu
          }
        )}>
        {WITH_SIDEBAR && <SidebarMenu />}
        <div
          className={cn(
            'min-h-[calc(100%-5rem)] box-border flex justify-center w-full',
            {
              'blur sm:blur-none': mobileMenu
            }
          )}>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Layout;
