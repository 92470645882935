import React from 'react';
import { Button, ButtonVariant } from '@/components/ui/button';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { Logo, TrashIcon } from '@icons';
import { MessageType } from '@hooks/useInsights';

interface IChatNavigationHeader {
  messages: MessageType[];
  hasSentMessage: boolean;
  setHasSentMessage: (value: boolean) => void;
  clearChat: () => void;
}

const ChatNavigationHeader = ({
  hasSentMessage,
  setHasSentMessage,
  messages,
  clearChat
}: IChatNavigationHeader) => {
  const showChatLayout = () => {
    setHasSentMessage(false);
  };

  const showChatWrapper = () => {
    setHasSentMessage(true);
  };

  const handleClearChat = () => {
    clearChat();
    showChatLayout();
  };

  return (
    <div className="flex items-center justify-center relative pb-4">
      <div className="absolute left-0">
        {hasSentMessage && (
          <Button
            onClick={showChatLayout}
            variant={ButtonVariant.SECONDARY}
            className="h-fit p-1">
            <IoIosArrowBack size={16} />
          </Button>
        )}
      </div>
      <div className="text-center flex items-center gap-2 py-1">
        <Logo className="h-[35px] w-[35px] rounded-[100px] border-main border-[2px]" />
        Unwink Brain
      </div>
      <div className="absolute right-0">
        {hasSentMessage ? (
          <Button
            variant={ButtonVariant.SECONDARY}
            onClick={handleClearChat}
            className="h-fit p-1">
            <TrashIcon />
          </Button>
        ) : (
          messages.length > 1 && (
            <Button
              onClick={showChatWrapper}
              variant={ButtonVariant.SECONDARY}
              className="h-fit p-1">
              <IoIosArrowForward size={16} />
            </Button>
          )
        )}
      </div>
    </div>
  );
};

export default ChatNavigationHeader;
