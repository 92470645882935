import React, { FC, useMemo } from 'react';
import { groupRatingData, groupTrendsData } from '@utils/chartHelper';
import { ISentimentOverTime } from '@all-types/csat-types';
import { getChartData } from '@utils/csatHelpers';
import { Button, ButtonVariant } from '@/components/ui/button';
import RaceBarChart from '@ui/charts/RaceBarChart';
import AreaChart from '@ui/charts/AreaChart';
import ReportNotificationsDialog from '@ui/dialogs/ReportNotificationsDialog';
import SkeletonGroup from '@ui/layout/SkeletonGroup';
import { AiOutlineFileExclamation } from 'react-icons/ai';

interface IReportsTab {
  reportSummary: JSX.Element | null;
  insightsReport: JSX.Element | null;
  isReportSummaryLoading: boolean;
  isInsightsReportLoading: boolean;
  avgScoreByCategory: any;
  isRatingEnabled: boolean;
  sentimentByTopic: any;
  sentimentOverTime?: ISentimentOverTime | null;
  isAvgScoreByCategoryLoading: boolean;
  isSentimentOverTimeLoading: boolean;
  isSentimentByTopicLoading: boolean;
}

const ReportsTab: FC<IReportsTab> = ({
  avgScoreByCategory,
  reportSummary,
  isReportSummaryLoading,
  isAvgScoreByCategoryLoading,
  isRatingEnabled,
  insightsReport,
  isInsightsReportLoading,
  isSentimentOverTimeLoading,
  sentimentByTopic,
  isSentimentByTopicLoading,
  sentimentOverTime
}) => {
  const avgScoreByCategoryData = useMemo(() => {
    return getChartData(avgScoreByCategory?.category);
  }, [avgScoreByCategory]);

  const sentimentOverTimeData = useMemo(() => {
    return groupTrendsData(sentimentOverTime);
  }, [sentimentOverTime]);

  const sentimentByTopicData = useMemo(() => {
    return {
      data: groupRatingData(sentimentByTopic),
      keys: Object.keys(sentimentByTopic || {}).filter((key) => key !== 'total')
    };
  }, [sentimentByTopic]);

  const isLoadingOrHasData = useMemo(
    () =>
      (insightsReport && reportSummary) ||
      isInsightsReportLoading ||
      isReportSummaryLoading,
    [
      insightsReport,
      reportSummary,
      isInsightsReportLoading,
      isReportSummaryLoading
    ]
  );

  return (
    <div className="h-full w-full">
      {isLoadingOrHasData ? (
        <div className="w-full">
          <div className="w-full bg-white mb-5 p-5 border border-gray-100 flex">
            <div className="w-full bg-white">
              {!isReportSummaryLoading && reportSummary ? (
                <div className="text-start">
                  <h1 className="text-gray-500 font-bold text-[19px]">
                    Summary
                  </h1>
                  {reportSummary}
                </div>
              ) : (
                <SkeletonGroup count={1} />
              )}
            </div>
            <div className="h-full w-[140px] mb-3 flex justify-end">
              <ReportNotificationsDialog
                onApply={() => console.log('Send reports successfully')}
                maxCharacters={30}>
                <Button variant={ButtonVariant.DEFAULT}>Send Report</Button>
              </ReportNotificationsDialog>
            </div>
          </div>
          <div className="flex space-x-5">
            <div className="w-1/2 bg-white p-5 border border-gray-100">
              {!isInsightsReportLoading && insightsReport ? (
                <div className="text-start">
                  <h1 className="text-gray-500 font-bold text-[19px]">
                    Insights
                  </h1>
                  {insightsReport}
                </div>
              ) : (
                <SkeletonGroup count={4} />
              )}
            </div>
            <div className="w-1/2 space-y-5">
              {!!sentimentOverTimeData.categories.length && (
                <AreaChart
                  wrapperClassName="w-full md:h-[440px] h-[410px]"
                  title="Sentiment over Time"
                  data={sentimentOverTimeData.data}
                  categories={sentimentOverTimeData.categories}
                  isLoading={isSentimentOverTimeLoading}
                />
              )}
              {isRatingEnabled && (
                <RaceBarChart
                  wrapperClassName="w-full md:h-[440px] h-[410px]"
                  data={avgScoreByCategoryData}
                  title="Average Score by Category"
                  yAxisLabel="Score"
                  isLoading={isAvgScoreByCategoryLoading}
                />
              )}
              <RaceBarChart
                wrapperClassName="w-full md:h-[440px] h-[410px]"
                data={sentimentByTopicData.data}
                title="Sentiment by Category"
                yAxisLabel="Percentage"
                stacking="percent"
                categories={sentimentByTopicData.keys}
                isLoading={isSentimentByTopicLoading}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center !h-[calc(100vh-232px)] w-full text-gray-500 text-xl font-semibold">
          <AiOutlineFileExclamation size={100} className="mb-2" />
          This report has no data.
        </div>
      )}
    </div>
  );
};

export default ReportsTab;
