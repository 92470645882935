import React from 'react';
import { IDataSchema, IFile, ISpaceCreator } from '@all-types/csat-types';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { formatFileSize } from '@utils/fileHelper';
import Spinner from '@ui/layout/Spinner';
import SpaceParticipants from '@ui/space/SpaceParticipants';

interface ISpaceInfoDialogProps {
  isSpaceOwner: boolean;
  title?: string;
  type?: string;
  dataSchema?: IDataSchema | null;
  files?: IFile[] | null;
  isLoading?: boolean;
  isOpen?: boolean;
  onOpenChange?: (value: boolean) => void;
  owner?: ISpaceCreator | null;
  participants?: string[] | null;
}

const getTotalFileSize = (files?: IFile[] | null): number => {
  if (!files || files.length === 0) {
    return 0;
  }
  return files.reduce((total, file) => total + (file.size || 0), 0);
};

const SpaceInfoDialog = ({
  owner,
  participants,
  title = 'Default',
  type,
  dataSchema,
  files,
  isLoading = true,
  isOpen = false,
  onOpenChange
}: ISpaceInfoDialogProps) => {
  const totalFileSizeInBytes = getTotalFileSize(files);
  const formattedFileSize = formatFileSize(totalFileSizeInBytes);

  return (
    <div>
      <Dialog open={isOpen} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[525px]">
          <DialogHeader>
            <DialogTitle className="text-2xl">{title}</DialogTitle>
          </DialogHeader>
          <div className="grid py-4 max-h-96 overflow-scroll">
            {!isLoading ? (
              <div>
                {owner && (
                  <div className="pb-2">
                    <span className="font-bold">
                      Owner:&nbsp;
                      <span className="font-normal text-gray-500">
                        {owner.name}
                      </span>
                    </span>
                  </div>
                )}
                <div className="pb-2">
                  <span className="font-bold">
                    Space type:&nbsp;
                    <span className="font-normal text-gray-500">
                      {type || 'None'}
                    </span>
                  </span>
                </div>
                <div>
                  <SpaceParticipants participants={participants} />
                </div>
                <div className="pb-2">
                  <span className="font-bold">File schema:&nbsp;</span>
                  {dataSchema && Object.keys(dataSchema).length > 0 ? (
                    <div className="font-semibold text-gray-500 ml-4">
                      {dataSchema.fields.map((field) => field.title).join(', ')}
                    </div>
                  ) : (
                    <span className="text-gray-500 font-normal">
                      Space doesn&apos;t have schema yet.
                    </span>
                  )}
                </div>
                {files && files.length > 0 && (
                  <div className="pb-2">
                    <span className="font-bold">
                      Space size:&nbsp;
                      <span className="text-gray-500 font-normal">
                        {formattedFileSize}
                      </span>
                    </span>
                  </div>
                )}
                {(type === 'File' || !type) && (
                  <div>
                    <span className="font-bold">Files:&nbsp;</span>
                    {files && files.length > 0 ? (
                      <ul className="ml-4">
                        {files.map((file, index) => (
                          <li key={index}>&#8226;&nbsp;{file.fileName}</li>
                        ))}
                      </ul>
                    ) : (
                      <span className="text-gray-500 font-normal">
                        No uploaded files
                      </span>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <Spinner />
            )}
          </div>
          <DialogFooter>
            <DialogClose>
              <Button>Close</Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SpaceInfoDialog;
