import React from 'react';

interface IAddSpaceButton {
  onClick?: () => void;
  name?: string;
}

const AddSpaceButton = ({ onClick = () => {}, name }: IAddSpaceButton) => {
  return (
    <div
      className="relative border-main bg-lightCyan sm:min-w-56 md:min-w-72 min-w-1/2 font-inter aspect-square border-2 rounded-lg flex justify-center items-center hover:opacity-50 hover:cursor-pointer overflow-x-hidden"
      onClick={onClick}>
      <div className="flex flex-col break-words">
        <div className="text-2xl mx-4 w-64 text-darkCyan font-bold">{name}</div>
      </div>
    </div>
  );
};

export default AddSpaceButton;
