import React, { FC, useState } from 'react';
import { cn } from '@/lib/utils';
import { validateEmail } from '@utils/inputValidateHelper';
import { Button, ButtonVariant } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';

interface INameDialogProps {
  onApply: (value: string) => void;
  defaultValue?: string;
  title?: string;
  description?: string;
  buttonTitle?: string;
  inputPlaceholder?: string;
  maxCharacters?: number;
  isEmail?: boolean;
  children: React.ReactNode;
}

const InputDialog: FC<INameDialogProps> = ({
  onApply,
  defaultValue = '',
  children,
  buttonTitle = 'OK',
  title = 'Name dialog',
  description = '',
  maxCharacters = Infinity,
  inputPlaceholder = 'Enter name',
  isEmail = false
}) => {
  const [value, setValue] = useState(defaultValue);
  const [isOpen, setIsOpen] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const isMaxLengthReached = value.length >= maxCharacters;

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (newValue.length <= maxCharacters) {
      setValue(newValue);
      if (isEmail) {
        setIsEmailValid(validateEmail(newValue));
      }
    }
  };

  const onApplyClick = () => {
    if (!isEmail || (isEmail && isEmailValid)) {
      onApply(value);
      setIsOpen(false);
    }
  };

  const onOpenChange = () => {
    setValue(defaultValue);
    setIsEmailValid(true);
    setIsOpen(!isOpen);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === 'Enter' &&
      value.trim() !== '' &&
      (!isEmail || (isEmail && isEmailValid))
    ) {
      onApplyClick();
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <div className="grid flex-1 gap-2">
            <Label htmlFor="link" className="sr-only">
              {isEmail ? 'Email' : 'Name'}
            </Label>
            <Input
              value={value}
              placeholder={inputPlaceholder}
              onChange={onInputChange}
              onKeyDown={handleKeyDown}
              className={isEmail && !isEmailValid ? 'border-red-500' : ''}
            />
            {isEmail && !isEmailValid && (
              <span className="text-red-500 text-xs">
                Please enter a valid email address.
              </span>
            )}
            <span
              className={cn('text-gray-500 text-xs', {
                'text-main': isMaxLengthReached
              })}>
              {isEmail ? '' : `Name is limited to ${maxCharacters} characters`}
            </span>
          </div>
        </div>
        <DialogFooter className="justify-end flex flex-row gap-2 items-end">
          <DialogClose asChild>
            <Button className="px-6" variant={ButtonVariant.SECONDARY}>
              Cancel
            </Button>
          </DialogClose>
          <DialogClose asChild disabled={!value || (isEmail && !isEmailValid)}>
            <Button className="px-6" onClick={onApplyClick}>
              {buttonTitle}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default InputDialog;
