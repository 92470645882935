import {
  ISentiment,
  ISentimentByData,
  ISentimentByDataValue,
  ISentimentOverTime
} from '@all-types/csat-types';
import { dateStringFromTimestamp, LONG_DATE_FORMAT } from '@utils/time';

export type GroupedData = {
  name: string;
  data: number[];
  color?: string;
}[];

export const SENTIMENT_COLORS: { [key: string]: string } = {
  Positive: 'rgb(1,180,4)',
  Neutral: 'rgb(255,222,0)',
  Negative: 'rgb(255,77,85)'
};

export function groupRatingData(data: ISentimentByData): GroupedData {
  const keys = ['Positive', 'Neutral', 'Negative'];

  // Initialize the result structure with those keys
  const result: GroupedData = keys.map((key) => ({
    name: key,
    data: [],
    color: SENTIMENT_COLORS[key]
  }));

  if (!data) {
    return result;
  }

  const sentimentsData = { ...data };
  if (Object.hasOwn(sentimentsData, 'total')) {
    delete sentimentsData.total;
  }
  // Map each entry's values to the corresponding key in the result
  Object.values(sentimentsData).forEach(
    ({ bySentiment }: ISentimentByDataValue) => {
      result.forEach((item) => {
        const sentimentValue = bySentiment?.[item.name as keyof ISentiment];
        item.data.push(sentimentValue || 0);
      });
    }
  );
  return result;
}

export function groupTrendsData(data?: ISentimentOverTime | null): {
  data: GroupedData;
  categories: string[];
} {
  const keys = ['Negative', 'Neutral', 'Positive'];

  // Initialize the result structure with those keys
  const result: GroupedData = keys.map((key) => ({
    name: key,
    data: [],
    color: SENTIMENT_COLORS[key]
  }));

  if (!data) {
    return { data: result, categories: [] };
  }

  const sentimentsData = { ...data };
  const sortedData = Object.entries(sentimentsData).sort(
    ([a], [b]) => Number(a) - Number(b)
  );
  // Map each entry's values to the corresponding key in the result
  sortedData.forEach(([key, value]) => {
    result.forEach((item) => {
      const sentimentValue = value?.[item.name as keyof ISentiment];
      item.data.push(sentimentValue || 0);
    });
  });
  return {
    data: result,
    categories: sortedData.map(([key]) =>
      dateStringFromTimestamp(key, LONG_DATE_FORMAT)
    )
  };
}
