import React, { FC } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ChartWrapper from '@ui/charts/ChartWrapper';
import { GroupedData } from '@utils/chartHelper';

interface IAreaChartProps {
  title: string;
  data: GroupedData;
  wrapperClassName?: string;
  categories?: string[] | number[];
  isLoading?: boolean;
}

const AreaChart: FC<IAreaChartProps> = ({
  title,
  isLoading,
  data,
  wrapperClassName,
  categories = []
}) => {
  const getTickPositions = (categoriesLength: number) => {
    const tickPositions = [];
    const totalTicks = 8;

    for (let i = 0; i < totalTicks; i++) {
      tickPositions.push(
        Math.round((i * (categoriesLength - 1)) / (totalTicks - 1))
      );
    }

    return tickPositions;
  };

  const options = {
    chart: {
      type: 'area'
    },
    title: {
      useHTML: true,
      text: title,
      align: 'left',
      style: {
        color: 'rgb(107 114 128)'
      }
    },
    accessibility: {
      point: {
        valueDescriptionFormat:
          '{index}. {point.category},{point.percentage:.1f}%.'
      }
    },
    yAxis: {
      labels: {
        format: '{value}%'
      },
      title: {
        enabled: false
      }
    },
    xAxis: {
      categories: categories,
      tickPositions: getTickPositions(categories.length),
      labels: {
        rotation: 0,
        align: 'center'
      }
    },
    tooltip: {
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>' +
        ': <b>{point.percentage:.1f}%<br/>',
      split: true
    },
    plotOptions: {
      series: {
        pointPlacement: 'on'
      },
      area: {
        stacking: 'percent',
        marker: {
          enabled: false
        },
        fillOpacity: 0.3
      }
    },
    series: data
  };

  return (
    <ChartWrapper className={wrapperClassName} isLoading={isLoading}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </ChartWrapper>
  );
};

export default AreaChart;
