import axios from 'axios';
import {
  IScoreResponse,
  IFilters,
  IInsight,
  ISentimentByData,
  ISentimentData,
  ITags,
  IInsightPrompt,
  IFeedbacks,
  ISentimentOverTime,
  IReportInsight
} from '@all-types/csat-types';
import { toaster } from '@ui/dialogs/ToastErrorMessage';

const apiUrl = process.env.REACT_APP_API_URL as string;

export default class CsatAPI {
  // eslint-disable-next-line no-use-before-define
  private static instance: CsatAPI;
  private host = apiUrl;

  private api: any;

  private constructor() {
    const token = localStorage.getItem('auth-token');
    this.api = axios.create({
      baseURL: this.host,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
  }

  public static getInstance(): CsatAPI {
    if (!CsatAPI.instance) {
      CsatAPI.instance = new CsatAPI();
    }

    return CsatAPI.instance;
  }

  public async getFeedbacks(
    id: string,
    params: Partial<IFilters> = {},
    pageIndex = 0,
    pageSize = 10
  ): Promise<IFeedbacks | null> {
    const response = await this.api
      .post(`/feedback/${id}?page=${pageIndex}&pageSize=${pageSize}`, {
        filter: params
      })
      .catch(function (error: any) {
        toaster.error({ text: 'Cannot get feedbacks' });
        console.error(error.toJSON());
      });
    if ([200, 201].includes(response?.status)) {
      console.info('Feedbacks', response.data);
      return response.data;
    }
    return null;
  }

  public async getAvgScore(
    id: string,
    paramId: string,
    params?: Partial<IFilters>
  ): Promise<IScoreResponse | null> {
    const response = await this.api
      .post(`/feedback/${id}/statistic/${paramId}`, { filter: params })
      .catch(function (error: any) {
        toaster.error({ text: 'Cannot get average score' });
        console.error(error.toJSON());
      });
    if ([200, 201].includes(response?.status)) {
      console.info('Average score', response.data);
      return response.data;
    }
    return null;
  }

  public async getAvgScoreBy(
    id: string,
    rateParamId: string,
    paramId: string,
    params?: Partial<IFilters>
  ): Promise<any | null> {
    const response = await this.api
      .post(`/feedback/${id}/avg/${rateParamId}/${paramId}`, { filter: params })
      .catch(function (error: any) {
        toaster.error({ text: 'Cannot get average score' });
        console.error(error.toJSON());
      });
    if ([200, 201].includes(response?.status)) {
      console.info('Average score', response.data);
      return response.data;
    }
    return null;
  }

  public async getFilledRatio(
    id: string,
    paramId: string,
    params?: Partial<IFilters>
  ): Promise<number | null> {
    const response = await this.api
      .post(`/feedback/${id}/fill-rate/${paramId}`, { filter: params })
      .catch(function (error: any) {
        toaster.error({ text: 'Cannot get filled ratio' });
        console.error(error.toJSON());
      });
    if ([200, 201].includes(response?.status)) {
      console.info('Filled ratio', response.data);
      return response.data;
    }
    return null;
  }

  public async getSentiment(
    id: string,
    paramId: string,
    params?: Partial<IFilters>
  ): Promise<ISentimentData | null> {
    const response = await this.api
      .post(`/feedback/${id}/statistic/${paramId}`, { filter: params })
      .catch(function (error: any) {
        toaster.error({ text: 'Cannot get sentiments data' });
        console.error(error.toJSON());
      });
    if ([200, 201].includes(response?.status)) {
      console.info('Sentiments data', response.data);
      return response.data;
    }
    return null;
  }

  public async getSentimentBy(
    id: string,
    paramId: string,
    params?: Partial<IFilters>
  ): Promise<ISentimentByData | null> {
    const response = await this.api
      .post(`/feedback/${id}/sentiment/${paramId}`, { filter: params })
      .catch(function (error: any) {
        toaster.error({ text: 'Cannot get sentiments data' });
        console.error(error.toJSON());
      });
    if ([200, 201].includes(response?.status)) {
      console.info('Sentiments data', response.data);
      return response.data;
    }
    return null;
  }

  public async getCategories(
    id: string,
    paramId: string,
    params?: Partial<IFilters>
  ): Promise<IScoreResponse | null> {
    const response = await this.api
      .post(`/feedback/${id}/statistic/${paramId}`, { filter: params })
      .catch((error: any) => {
        toaster.error({ text: 'Cannot get categories' });
        console.error(error.toJSON());
      });
    if ([200, 201].includes(response?.status)) {
      console.info('Categories', response.data);
      return response.data;
    }
    return null;
  }

  public async getTags(
    id: string,
    params?: Partial<IFilters>
  ): Promise<ITags | null> {
    const response = await this.api
      .post(`/tag/count/${id}`, { filter: params })
      .catch((error: any) => {
        toaster.error({ text: 'Cannot get tags' });
        console.error(error.toJSON());
      });
    if ([200, 201].includes(response?.status)) {
      console.info('Tags', response.data);
      return response.data;
    }
    return null;
  }

  public async getInsights(
    id: string,
    params?: Partial<IFilters>
  ): Promise<IInsight | string | null> {
    const response = await this.api
      .post(`/insight/report/${id}`, { filter: params })
      .catch((error: any) => {
        toaster.error({ text: 'Cannot get insights' });
        console.error(error.toJSON());
      });
    if ([200, 201].includes(response?.status)) {
      console.info('Insights', response.data);
      return response.data;
    }
    return null;
  }

  public async getReportInsights(
    id: string,
    params?: Partial<IFilters>
  ): Promise<IReportInsight | string | null> {
    const response = await this.api
      .post(`/insight/insight/${id}`, { filter: params })
      .catch((error: any) => {
        toaster.error({ text: 'Cannot get report insights' });
        console.error(error.toJSON());
      });
    if ([200, 201].includes(response?.status)) {
      console.info('Report insights', response.data);
      return response.data;
    }
    return null;
  }

  public async getReportSummary(
    id: string,
    params?: Partial<IFilters>
  ): Promise<IReportInsight | string | null> {
    const response = await this.api
      .post(`/insight/summary/${id}`, { filter: params })
      .catch((error: any) => {
        toaster.error({ text: 'Cannot get report insights' });
        console.error(error.toJSON());
      });
    if ([200, 201].includes(response?.status)) {
      console.info('Report insights', response.data);
      return response.data;
    }
    return null;
  }

  public async getInsightsByQuestion(
    id: string,
    question = '',
    params?: Partial<IFilters>,
    config?: { signal?: AbortSignal }
  ): Promise<IInsightPrompt | string | null> {
    const response = await this.api
      .post(`/insight/report/${id}`, { filter: params, question }, config)
      .catch((error: any) => {
        if (error.name === 'CanceledError') {
          return null;
        }
        toaster.error({ text: 'Cannot get insights' });
        console.error(error.toJSON());
      });
    if ([200, 201].includes(response?.status)) {
      console.info('Insights', response.data);
      return response.data;
    }
    return null;
  }

  public async getFilters(id: string): Promise<IFilters | null> {
    const response = await this.api
      .post(`/filter/${id}`, {})
      .catch((error: any) => {
        toaster.error({ text: 'Cannot get filters' });
        console.error(error.toJSON());
      });
    if ([200, 201].includes(response?.status)) {
      console.info('Filters', response.data);
      return response.data;
    }
    return null;
  }

  public async getSentimentOverTimeTrends(
    id: string,
    paramId: string,
    params?: Partial<IFilters>
  ): Promise<ISentimentOverTime | null> {
    const response = await this.api
      .post(`/feedback/${id}/trends/${paramId}/sentiment`, { filter: params })
      .catch(function (error: any) {
        toaster.error({ text: 'Cannot get sentiments data' });
        console.error(error.toJSON());
      });
    if ([200, 201].includes(response?.status)) {
      return response.data;
    }
    return null;
  }
}
