import React from 'react';
import ReactDOM from 'react-dom';

interface ITooltipPortalProps {
  children: React.ReactNode;
}

const TooltipPortal: React.FC<ITooltipPortalProps> = ({ children }) => {
  const portalRoot = document.getElementById('tooltip-portal-root');

  if (!portalRoot) {
    const div = document.createElement('div');
    div.id = 'tooltip-portal-root';
    document.body.appendChild(div);
  }

  return ReactDOM.createPortal(children, portalRoot || document.body);
};

export default TooltipPortal;
