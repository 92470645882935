import { useMemo } from 'react';
import { DateRange } from 'react-day-picker';
import { DateTimestampType, dateToTimestamp } from '@utils/time';
import { IFilters } from '@all-types/csat-types';

interface IUseDateFilterProps {
  onChangeFilters: (data: DateTimestampType) => void;
  selected?: Partial<IFilters>;
  dateFilters?: number[];
}

const useDateFilter = ({
  onChangeFilters,
  selected,
  dateFilters
}: IUseDateFilterProps) => {
  const onDateChange = (date: DateRange) => {
    const dateValue: DateTimestampType = {};
    if (date?.from) {
      dateValue.startDate = date?.from ? dateToTimestamp(date.from) : undefined;
      dateValue.endDate = date?.to ? dateToTimestamp(date.to) : undefined;
    }
    onChangeFilters(dateValue);
  };

  const defaultDateValues = useMemo(() => {
    const from = dateFilters?.[0];
    const to = dateFilters?.[1];
    return {
      from: from ? new Date(from) : undefined,
      to: to ? new Date(to) : undefined
    };
  }, [dateFilters]);

  const dateValues = useMemo(() => {
    const from = selected?.startDate;
    const to = selected?.endDate;
    return {
      from: from ? new Date(from) : undefined,
      to: to ? new Date(to) : undefined
    };
  }, [selected]);

  return { onDateChange, defaultDateValues, dateValues };
};

export default useDateFilter;
