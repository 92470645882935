import React from 'react';
import {
  Carousel,
  CarouselContent,
  CarouselItem
} from '@/components/ui/carousel';

const top5AiQuestions = [
  'How to improve customer support?',
  'How to change negative objectives?',
  'How to improve customer support as much as possible?',
  'How to follow our steps?',
  'Who is the best agent?'
];

interface IChatLayoutProps {
  onQuestionSelect: (question: string) => void;
  disabled: boolean;
}

const ChatLayout: React.FC<IChatLayoutProps> = ({
  onQuestionSelect,
  disabled
}) => {
  return (
    <div className="h-[calc(100%-138px)] flex flex-col justify-between">
      <div className="flex flex-col items-start">
        <span>Ask AI</span>
        <span className="text-sm font-normal text-gray-500 text-left pt-1">
          Ask about anything in your space. Unwink will also provide
          recommendations to improve customer support.
        </span>
      </div>
      <Carousel>
        <div className="pb-2 text-left font-medium">Top 5 Ask AI questions</div>
        <CarouselContent
          className="pb-4 overflow-x-scroll w-full ml-0 gap-2"
          aria-disabled={true}>
          {top5AiQuestions.map((question, index) => (
            <CarouselItem
              key={index}
              onClick={() => !disabled && onQuestionSelect(question)}
              className={`sm:basis-[32.45%] border rounded-[0.75rem] h-[90px] w-[180px] text-left flex items-center py-2 text-darkCyan cursor-pointer px-4 ${
                disabled ? 'cursor-not-allowed' : 'hover:bg-gray-50'
              }`}>
              {question}
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </div>
  );
};

export default ChatLayout;
