import axios from 'axios';
import { toaster } from '@ui/dialogs/ToastErrorMessage';

export default class DemoAPI {
  // eslint-disable-next-line no-use-before-define
  private static instance: DemoAPI;
  private host = 'https://seashell-app-7iau6.ondigitalocean.app';
  // private static host = 'https://back.unwink.ai';
  // private static host = 'http://localhost:3100';

  private api: any;

  private constructor() {
    const token = localStorage.getItem('auth-token');
    this.api = axios.create({
      baseURL: this.host,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
  }

  public static getInstance(): DemoAPI {
    if (!DemoAPI.instance) {
      DemoAPI.instance = new DemoAPI();
    }

    return DemoAPI.instance;
  }

  public async getFile(fileName: string): Promise<any> {
    const token = localStorage.getItem('auth-token');
    const response = await fetch(`${this.host}/file/${fileName}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        responseType: 'blob'
      }
    }).then((response) => response.blob());
    if (response) {
      return URL.createObjectURL(response);
    }
    return null;
  }

  public async uploadFile(formData: FormData): Promise<any> {
    const response = await this.api
      .post('/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json'
        }
      })
      .catch(function (error: any) {
        toaster.error({ text: 'Cannot upload file' });
        console.error(error.toJSON());
      });
    if ([200, 201].includes(response?.status)) {
      console.info(response.data);
      return response.data;
    }
    return null;
  }

  public async getTranscription(fileName: string): Promise<any> {
    const response = await this.api
      .post(`/transcription/${fileName}`, {})
      .catch(function (error: any) {
        toaster.error({ text: 'Cannot get transcription' });
        console.error(error.toJSON());
      });
    if ([200, 201].includes(response?.status)) {
      console.info(response.data);
      return response.data;
    }
    return null;
  }
}
