import React, { useState } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem
} from '@/components/ui/dropdown-menu';
import InputDialog from '@ui/dialogs/InputDialog';
import ConfirmationModal from '@ui/dialogs/ConfirmationModal';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { RxFileText } from 'react-icons/rx';
import { MdOutlineAddCircleOutline } from 'react-icons/md';

interface ISpaceActions {
  onInfoOpen: () => void;
  onAddParticipant: (email: string) => Promise<boolean>;
  title: string;
  onDelete: () => void;
  onSelectSpace: () => void;
}

const SpaceActions = ({
  onInfoOpen,
  onAddParticipant,
  title,
  onDelete,
  onSelectSpace
}: ISpaceActions) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleAddParticipant = async (email: string) => {
    await onAddParticipant(email);
    handleMenuClose();
  };

  const isDefault = title === 'Default';

  const deleteSpaceButton = (
    <>
      {!isDefault && (
        <ConfirmationModal
          title={`Are you sure you want to delete this space "${title}"?`}
          onDelete={() => {
            onDelete();
            handleMenuClose();
          }}
        />
      )}
    </>
  );

  const handleMenuOpenChange = (isOpen: boolean) => {
    setMenuOpen(isOpen);
    if (isOpen) {
      onSelectSpace();
    }
  };

  const handleInfoSelect = () => {
    onInfoOpen();
    handleMenuClose();
  };

  return (
    <div className="relative">
      <DropdownMenu open={menuOpen} onOpenChange={handleMenuOpenChange}>
        <DropdownMenuTrigger>
          <span className="text-darkCyan font-bold rounded-[100px] hover:bg-gray-100 p-2 flex items-center hover:cursor-pointer">
            <BsThreeDotsVertical size={24} />
          </span>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="end"
          className="overflow-hidden w-60 rounded-[0px] border-none bg-gray-75 px-0 py-0">
          <DropdownMenuItem
            onSelect={handleInfoSelect}
            className="h-10 font-normal cursor-pointer px-2 hover:bg-gray-100 flex items-center">
            <RxFileText />
            <span className="text-sm ml-1">View details</span>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <InputDialog
              title="Add new participant"
              inputPlaceholder="Enter user email"
              buttonTitle="Add"
              onApply={handleAddParticipant}
              maxCharacters={30}
              isEmail={true}>
              <span className="text-darkCyan h-10 flex items-center px-2 hover:bg-gray-100 cursor-pointer">
                <MdOutlineAddCircleOutline />
                <span className="text-sm ml-1">Add participant</span>
              </span>
            </InputDialog>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>{deleteSpaceButton}</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default SpaceActions;
